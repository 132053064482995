import { Component } from 'react'
import { Col, Row } from 'antd'
import {
  KeyValueGrid,
  StylewherePage,
  DefaultHeader,
  NotFound,
  Section,
  GridCards,
  TableListReport,
} from '../../../components'
import Reports from '../../../api/Reports'
import {
  DetailCell,
  StwOperation,
  StwOperationAction,
  StwFullInventory,
  StwInventoryCounters,
  StwReportQueryResult,
} from '../../../api'
import { __, T } from '../../../shared/i18n'
import Inventories from '../../../api/Inventories'
import { AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import { SkeletonAttributeReport } from '../../../constants'
import { showModal } from '../../../components/modalPageUtils'
import {
  getExpectedCard,
  getMissingCard,
  getReadCard,
  getUnexpectedCard,
  getFragmentObject,
  getMultipleTitle,
  getBackURL,
  getHeaderOperationActions,
} from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'

interface State {
  inventory?: StwFullInventory
  inventoryCounters?: StwInventoryCounters
  sortValues: any
  reportInstance: StwReportQueryResult | undefined
  configuration: DetailCell[]
  title?: string
  loader: boolean
  actions: StwOperationAction[]
}

class InventoryOperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      reportInstance: undefined,
      configuration: [],
      loader: true,
      inventory: {
        zones: [],
        ignoreZones: [],
      },
      sortValues: {},
      actions: [],
    }
  }

  componentDidMount = async () => {
    await this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const operation: StwOperation = await Operations.get<StwOperation>(match.params.operationId)
        const inventory = await Inventories.getFull(instance.id)
        const configuration = await Reports.getConfiguration(reportId, params)
        if (inventory && inventory.id) {
          const inventoryCounters = await Inventories.getCounters(inventory.id!)
          const sortValues = this.getInventoryEntryTablePaginationSort(inventory)
          this.setState({
            reportInstance: instance,
            loader: false,
            inventory: inventory,
            inventoryCounters: inventoryCounters,
            sortValues: sortValues,
            configuration: configuration,
            actions: operation && operation.actions && operation.actions !== null ? operation.actions : [],
          })
        } else {
          this.setState({
            reportInstance: instance,
            configuration: configuration,
            loader: false,
          })
        }
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  getInventoryEntryTableTitle(inventory: StwFullInventory): string {
    switch (inventory.mode) {
      case 'NONE' || 'EXTERNAL_BY_PRODUCT':
        return 'fields.labels.products'
      case 'STW_STOCK' || 'EXTERNAL_BY_ITEM':
      default:
        return 'fields.labels.items'
    }
  }

  getInventoryIgnoredEntryTableTitle(inventory: StwFullInventory): string {
    switch (inventory.mode) {
      case 'NONE' || 'EXTERNAL_BY_PRODUCT':
        return 'fields.labels.ignoredProducts'
      case 'STW_STOCK' || 'EXTERNAL_BY_ITEM':
      default:
        return 'fields.labels.ignoredItems'
    }
  }

  getInventoryEntryTablePaginationSort(inventory: StwFullInventory): {
    [key: string]: AdvancedTableSortDirection
  } {
    switch (inventory.mode) {
      case 'NONE' || 'EXTERNAL_BY_PRODUCT':
        return { expectedQuantity: AdvancedTableSortDirection.desc }
      case 'STW_STOCK' || 'EXTERNAL_BY_ITEM':
      default:
        return {}
    }
  }

  getInventoryEntryReportId(inventory: StwFullInventory): string {
    switch (inventory.mode) {
      case 'NONE' || 'EXTERNAL_BY_PRODUCT':
        return 'inventoryEntryAggregate'
      case 'STW_STOCK' || 'EXTERNAL_BY_ITEM':
      default:
        return 'inventoryEntry'
    }
  }

  renderCounters(reportInstance: StwReportQueryResult, inventoryCounters: StwInventoryCounters) {
    return (
      <Row className="row-cards-inventory" gutter={[15, 15]}>
        {this.renderDetectedCounters(reportInstance, inventoryCounters)}
        {this.shouldRenderExpectedCounters(reportInstance) &&
          this.renderExpectedCounters(reportInstance, inventoryCounters)}
        {this.shouldRenderUnexpectedCounter(reportInstance) &&
          this.renderUnexpectedCounters(reportInstance, inventoryCounters)}
        {this.shouldRenderMissingCounter(reportInstance) &&
          this.renderMissingCounters(reportInstance, inventoryCounters)}
      </Row>
    )
  }

  renderDetectedCounters(reportInstance: StwReportQueryResult, inventoryCounters: StwInventoryCounters) {
    const totalDetectedCounter = (inventoryCounters.rfidDetected || 0) + (inventoryCounters.barcodeDetected || 0)

    return reportInstance.detectedCounter !== undefined ? (
      <Col span={12}>
        <GridCards cards={[{ rows: [getReadCard(__('fields.labels.read'), totalDetectedCounter)] }]} />
      </Col>
    ) : (
      <Col span={24}>
        <GridCards
          cards={[
            {
              rows: [getReadCard(`${__('fields.labels.read')} - ${__('misc.total')}`, totalDetectedCounter)],
            },
            { rows: [getReadCard(`${__('fields.labels.read')} - RFID`, inventoryCounters.rfidDetected || 0)] },
            {
              rows: [getReadCard(`${__('fields.labels.read')} - Barcode`, inventoryCounters.barcodeDetected || 0)],
            },
          ]}
        />
      </Col>
    )
  }

  renderExpectedCounters(reportInstance: StwReportQueryResult, inventoryCounters: StwInventoryCounters) {
    const totalDetectedAndExpectedCounter =
      (inventoryCounters.rfidDetected || 0) +
      (inventoryCounters.barcodeDetected || 0) -
      (inventoryCounters.rfidUnexpected || 0) -
      (inventoryCounters.barcodeUnexpected || 0)
    const totalExpectedCounter = (inventoryCounters.rfidExpected || 0) + (inventoryCounters.barcodeExpected || 0)

    return reportInstance.detectedAndExpectedCounter !== undefined && reportInstance.expectedCounter !== undefined ? (
      <Col span={12}>
        <GridCards
          cards={[
            {
              rows: [
                getExpectedCard(__('fields.labels.expected'), totalDetectedAndExpectedCounter, totalExpectedCounter),
              ],
            },
          ]}
        />
      </Col>
    ) : (
      <Col span={24}>
        <GridCards
          cards={[
            {
              rows: [
                getExpectedCard(
                  `${__('fields.labels.expected')} - ${__('misc.total')}`,
                  totalDetectedAndExpectedCounter || 0,
                  totalExpectedCounter
                ),
              ],
            },
            {
              rows: [
                getExpectedCard(
                  `${__('fields.labels.expected')} - RFID`,
                  (inventoryCounters.rfidDetected || 0) + (inventoryCounters.rfidExpected || 0),
                  inventoryCounters.rfidExpected || 0
                ),
              ],
            },
            {
              rows: [
                getExpectedCard(
                  `${__('fields.labels.expected')} - Barcode`,
                  (inventoryCounters.barcodeDetected || 0) + (inventoryCounters.barcodeExpected || 0),
                  inventoryCounters.barcodeExpected || 0
                ),
              ],
            },
          ]}
        />
      </Col>
    )
  }

  renderUnexpectedCounters(reportInstance: StwReportQueryResult, inventoryCounters: StwInventoryCounters) {
    const totalUnexpectedCounter = (inventoryCounters.rfidUnexpected || 0) + (inventoryCounters.barcodeUnexpected || 0)

    return reportInstance.unexpectedCounter !== undefined ? (
      <Col span={12}>
        <GridCards
          cards={[
            {
              rows: [getUnexpectedCard(__('fields.labels.unexpected'), totalUnexpectedCounter)],
            },
          ]}
        />
      </Col>
    ) : (
      <Col span={24}>
        <GridCards
          cards={[
            {
              rows: [
                getUnexpectedCard(`${__('fields.labels.unexpected')} - ${__('misc.total')}`, totalUnexpectedCounter),
              ],
            },
            {
              rows: [
                getUnexpectedCard(`${__('fields.labels.unexpected')} - RFID`, inventoryCounters.rfidUnexpected || 0),
              ],
            },
            {
              rows: [
                getUnexpectedCard(
                  `${__('fields.labels.unexpected')} - Barcode`,
                  inventoryCounters.barcodeUnexpected || 0
                ),
              ],
            },
          ]}
        />
      </Col>
    )
  }

  renderMissingCounters(reportInstance: StwReportQueryResult, inventoryCounters: StwInventoryCounters) {
    const totalMissingCounter = (inventoryCounters.rfidMissing || 0) + (inventoryCounters.barcodeMissing || 0)

    return reportInstance.missingCounter !== undefined ? (
      <Col span={12}>
        <GridCards
          cards={[
            {
              rows: [getMissingCard(__('fields.labels.missing'), totalMissingCounter)],
            },
          ]}
        />
      </Col>
    ) : (
      <Col span={24}>
        <GridCards
          cards={[
            {
              rows: [getMissingCard(`${__('fields.labels.missing')} - ${__('misc.total')}`, totalMissingCounter)],
            },
            { rows: [getMissingCard(`${__('fields.labels.missing')} - RFID`, inventoryCounters.rfidMissing || 0)] },
            {
              rows: [getMissingCard(`${__('fields.labels.missing')} - Barcode`, inventoryCounters.barcodeMissing || 0)],
            },
          ]}
        />
      </Col>
    )
  }

  shouldRenderExpectedCounters(reportInstance: StwReportQueryResult): boolean {
    return (
      (reportInstance.detectedAndExpectedCounter !== undefined && reportInstance.expectedCounter !== undefined) ||
      (reportInstance.totalDetectedAndExpectedCounter !== undefined &&
        reportInstance.rfidDetectedAndExpectedCounter !== undefined &&
        reportInstance.barcodeDetectedAndExpectedCounter !== undefined &&
        reportInstance.totalExpectedCounter !== undefined &&
        reportInstance.rfidExpectedCounter !== undefined &&
        reportInstance.barcodeExpectedCounter !== undefined)
    )
  }

  shouldRenderUnexpectedCounter(reportInstance: StwReportQueryResult): boolean {
    return (
      reportInstance.unexpectedCounter !== undefined ||
      (reportInstance.totalUnexpectedCounter !== undefined &&
        reportInstance.rfidUnexpectedCounter !== undefined &&
        reportInstance.barcodeUnexpectedCounter !== undefined)
    )
  }

  shouldRenderMissingCounter(reportInstance: StwReportQueryResult): boolean {
    return (
      reportInstance.missingCounter !== undefined ||
      (reportInstance.totalMissingCounter !== undefined &&
        reportInstance.rfidMissingCounter !== undefined &&
        reportInstance.barcodeMissingCounter !== undefined)
    )
  }

  decorateWithZonesAndFilters(configuration: DetailCell[]): DetailCell[] {
    const { inventory } = this.state
    const filters: DetailCell[] = []
    if (inventory && inventory.filter && inventory.filter.product) {
      const keys = Object.keys(inventory.filter.product)
      keys.forEach((key) => {
        filters.push({
          title: key,
          attribute: key,
          type: 'custom',
          render: () => inventory.filter!.product[key].join(', '),
        })
      })
    }
    return [
      ...configuration,
      {
        title: __('fields.labels.zones'),
        attribute: 'zones',
        type: 'custom',
        render: () =>
          inventory && inventory.zones
            ? inventory.zones
                .map((zone) => `${zone.code} - ${zone.description ? `- ${zone.description}` : ''}`)
                .join(', ')
            : 'n/a',
      },
      {
        title: __('fields.labels.ignoreZones'),
        attribute: 'zones',
        type: 'custom',
        render: () =>
          inventory && inventory.ignoreZones && inventory.ignoreZones.length > 0
            ? inventory.ignoreZones
                .map((zone) => `${zone.code} ${zone.description ? `- ${zone.description}` : ''}`)
                .join(', ')
            : 'n/a',
      },
      {
        title: __('fields.labels.checkZone'),
        attribute: 'zones',
        type: 'custom',
        render: () =>
          inventory && inventory.checkZone && inventory.checkZone.id
            ? `${inventory.checkZone.code} ${
                inventory.checkZone.description ? `- ${inventory.checkZone.description}` : ''
              }`
            : 'n/a',
      },
      {
        title: __('fields.labels.lostZone'),
        attribute: 'zones',
        type: 'custom',
        render: () =>
          inventory && inventory.lostZone && inventory.lostZone.id
            ? `${inventory.lostZone.code} ${
                inventory.lostZone.description ? `- ${inventory.lostZone.description}` : ''
              }`
            : 'n/a',
      },
      ...filters,
    ]
  }

  onViewDetail = (record: any): void => {
    showModal({
      type: 'INVENTORY_ENTRY_ITEMS',
      data: { productId: record.itemProductId, inventoryId: this.state.inventory!.id },
    })
  }

  render() {
    const { breadcrumbs, queryString, match } = this.props
    const { reportInstance, configuration, inventory, inventoryCounters, sortValues, loader, actions } = this.state
    const fragment = getFragmentObject(inventory, 'id')
    const itemsTitle = inventory && inventory.id ? __(this.getInventoryEntryTableTitle(inventory!)) : ''
    const itemsIgnoredTitle = inventory && inventory.id ? __(this.getInventoryIgnoredEntryTableTitle(inventory!)) : ''
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={
            reportInstance
              ? getHeaderOperationActions(
                  'inventory',
                  'inventory',
                  actions,
                  match.params.operationId,
                  match.params.operationInstanceId
                )
              : []
          }
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <Section>
                <KeyValueGrid
                  skeleton={loader}
                  fields={loader ? SkeletonAttributeReport : this.decorateWithZonesAndFilters(configuration)}
                  data={reportInstance}
                />
                {!loader &&
                  reportInstance &&
                  inventoryCounters &&
                  this.renderCounters(reportInstance, inventoryCounters)}
              </Section>
              {inventory && inventory.id && (
                <>
                  <TableListReport
                    {...this.props}
                    reportId={this.getInventoryEntryReportId(inventory)}
                    actions={[]}
                    fixedTableHeight
                    exportModalTitle={inventory?.place?.code ?? itemsTitle}
                    headerType="boxed"
                    title={itemsTitle}
                    customColumnPrefix="inventoryDetailEntries"
                    disableLocation
                    reportActions={{ active: true, onlyedit: false, onClick: this.onViewDetail }}
                    reportParams={{ inventoryId: inventory.id }}
                    sortValues={sortValues}
                  />
                  {inventory.operation?.options?.saveInventoryIgnoredWithReasonIdentifiers && (
                    <TableListReport
                      {...this.props}
                      reportId="ignoredWithReasonIdentifier"
                      actions={[]}
                      fixedTableHeight
                      exportModalTitle={inventory?.place?.code ?? itemsIgnoredTitle}
                      exportModalFilenamePrefix="ignored_with_reason"
                      headerType="boxed"
                      title={itemsIgnoredTitle}
                      customColumnPrefix="inventoryDetailIgnoredWithReasonEntries"
                      disableLocation
                      reportActions={{ active: true, onlyedit: false, onClick: this.onViewDetail }}
                      reportParams={{ inventoryId: inventory.id }}
                      sortValues={sortValues}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default InventoryOperationDetail
