import { Component } from 'react'
import { Row, Typography } from 'antd'
import IconNoData from '../../icon/icon-no-data-found.png'

const { Text } = Typography

interface Props {
  title: string
  text?: string
  fullheight?: boolean
}

export class NotFound extends Component<Props> {
  getNotFoundHeight = () => {
    const { innerHeight: height } = window
    const h = height - 191 //250
    return h
  }

  render() {
    const { title, text, fullheight } = this.props
    return (
      <Row className="stw-not-found" style={{ height: fullheight ? this.getNotFoundHeight() : 'initial' }}>
        <div className="icona" style={{ backgroundImage: `url(${IconNoData})` }} />
        <Text className="stw-not-found-title" strong>
          {title}
        </Text>
        {text && <Text>{text}</Text>}
      </Row>
    )
  }
}
