import { Component, ReactNode } from 'react'
import { EyeOutlined } from '@ant-design/icons'
import { TableList, DynamicImage } from '..'
import { ExtendRouteComponentProps } from '../../types'
import { StwReportFilterMetadata, StwReportMetadata, StwOperation } from '../../api'
import Reports from '../../api/Reports'
import Operations from '../../api/Operations'
import {
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
  AdvancedTableFilter,
  AdvancedTableConfig,
  AdvancedTableColumn,
} from '../AdvancedTable'
import { AdvancedTableRowColumn } from '../AdvancedTableRowColumn'
import { BooleanComboValues, PRODUCT_IMAGE_LIST_SIZE } from '../../constants'
import { __ } from '../../shared/i18n'
import { StylewhereTab } from './Tabs'

export interface ReportActions {
  active: boolean
  onlyedit: boolean
  editCapabilites?: string
  canDelete?: boolean
  customClass?:
    | 'stw-extra-small'
    | 'stw-small'
    | 'stw-small-medium'
    | 'stw-medium'
    | 'stw-extra-medium'
    | 'stw-large'
    | 'stw-extra-large'
  onClick?: any
  condition?: any
  fieldId?: string
}

interface Props extends ExtendRouteComponentProps {
  reportId: string
  operationType?: string
  operationId?: string
  enabledColumnImage?: { active: boolean; index: number }
  reportActions?: ReportActions
  actions?: ReadonlyArray<ReactNode>
  tabs?: StylewhereTab[]
  title?: string
  titleEmpty?: string
  disabledReportExport?: boolean
  deleteRecord?: any
  headerType?: 'paged' | 'boxed' | 'unset'
  exportModalTitle?: string
  exportModalFilenamePrefix?: string
  fixedTableHeight?: boolean
  customColumnPrefix?: string
  disabledColumnPrefix?: boolean
  reportParams?: any
  sortValues?: any
  disabledNoDataFound?: boolean
  disableLocation?: boolean
  pathDetail?: string
  numberRecodPage?: number
  disableColumnFilters?: boolean
  disabledReloadData?: boolean
  tableBorder?: boolean
  hiddenEmptyTable?: boolean
}

interface State {
  tableConfig: AdvancedTableConfig
  report: string
  title: string
}

export class TableListReport extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      report: '',
      title: '',
      tableConfig: {
        columns: [],
        filterDefinitions: [],
      },
    }
  }

  getReportData = async () => {
    const { reportId, operationId, title } = this.props
    if (reportId) {
      return {
        title: title || `${__(`report.${reportId}`)}`,
        id: reportId,
      }
    }
    const op = await Operations.get<StwOperation>(operationId)
    return {
      title: `${op.description || op.type || ''}`,
      id: op.enabled ? `OPLOG_${op.type}` : '',
    }
  }

  componentDidMount() {
    this._init()
  }

  _init = async () => {
    const data = await this.getReportData()
    this.setState(
      {
        tableConfig: {
          columns: [],
          filterDefinitions: [],
        },
        report: data.id,
        title: data.title,
      },
      this.loadReport
    )
  }

  loadReport = async () => {
    const { report } = this.state
    const {
      operationId,
      fixedTableHeight,
      customColumnPrefix,
      disabledColumnPrefix,
      exportModalTitle,
      exportModalFilenamePrefix,
      disabledNoDataFound,
      disableLocation,
      disableColumnFilters,
      disabledReloadData,
      tableBorder,
      hiddenEmptyTable,
    } = this.props
    let result
    if (operationId) {
      result = await Reports.metadata(report, { operationId: operationId })
    } else {
      result = await Reports.metadata(report)
    }
    if (result && !result.errorId) {
      const filtersMetadata = this.getFilterMetadata(result)
      const config: AdvancedTableConfig = {
        columns: this.getTableColumns(result),
        filterDefinitions: this.getFilterDefinitions(filtersMetadata),
        filterParameters: this.getFilterParameters(filtersMetadata),
        manageColumnsPrefix: disabledColumnPrefix
          ? undefined
          : `${report}${customColumnPrefix ? `_${customColumnPrefix}` : ''}`,
        activeDefaultClassColumn: true,
        fixedTableHeight: fixedTableHeight,
        exportModalTitle: exportModalTitle,
        exportModalFilenamePrefix: exportModalFilenamePrefix || 'export',
        disabledNoDataFound: disabledNoDataFound,
        disableLocation: disableLocation,
        disableColumnFilters: disableColumnFilters,
        disabledReloadData: disabledReloadData,
        tableBorder: tableBorder,
        hiddenEmptyTable: hiddenEmptyTable,
      }
      this.setState({
        tableConfig: config,
      })
    }
  }

  getBasePath = () => {
    const { breadcrumbs } = this.props
    if (breadcrumbs) return breadcrumbs.fragments[breadcrumbs.fragments.length - 1].path
    return ''
  }

  getTableColumns = (reportMetadata: StwReportMetadata): AdvancedTableColumn[] => {
    const basePath = this.getBasePath()
    const { reportActions, enabledColumnImage, pathDetail } = this.props
    const tableColumns: AdvancedTableColumn[] = reportMetadata.columns.map((column) => ({
      key: column.parameter,
      title: __(`fields.labels.${column.parameter}`, column.description),
      dataIndex: column.parameter,
      type: column.type,
      hidden: column.hidden || column.technicalField,
      sortable: !column.unsortable,
      unmanageable: column.technicalField,
    }))
    if (enabledColumnImage && enabledColumnImage.active) {
      const imageColumn: AdvancedTableColumn = {
        title: 'Image',
        key: 'image',
        className: 'stw-small',
        //unmanageable: true,
        sortable: false,
        render: (text, record) => <DynamicImage size={PRODUCT_IMAGE_LIST_SIZE} record={record} />,
      }
      tableColumns.splice(enabledColumnImage.index, 0, imageColumn)
    }

    if (reportActions && reportActions.active) {
      if (reportActions.onClick) {
        tableColumns.push({
          title: '',
          key: 'action',
          className: reportActions.customClass || 'stw-extra-small',
          fixedType: 'right',
          unmanageable: true,
          sortable: false,
          render: (text, record) => (
            <>
              {(!reportActions.condition || (reportActions.condition && record[reportActions.condition] > 0)) && (
                <AdvancedTableRowColumn
                  actions={[
                    {
                      content: <EyeOutlined />,
                      type: 'default',
                      onclick: () => reportActions.onClick(record),
                    },
                  ]}
                />
              )}
            </>
          ),
        })
      } else {
        tableColumns.push({
          title: '',
          key: 'action',
          className: reportActions.customClass || 'stw-extra-small',
          fixedType: 'right',
          unmanageable: true,
          sortable: false,
          reportActions: reportActions,
          basePath: pathDetail || basePath,
        })
      }
    }
    return tableColumns
  }

  getFilterMetadata = (reportMetadata: StwReportMetadata): ReadonlyArray<StwReportFilterMetadata> => {
    return reportMetadata.filters
      ? reportMetadata.filters.filter((filterMetadata: StwReportFilterMetadata) => !filterMetadata.technical)
      : []
  }

  getFilterDefinitions = (filtersMetadata: ReadonlyArray<StwReportFilterMetadata>): AdvancedTableFiltersDef => {
    return filtersMetadata
      .filter((filterMetadata) => !filterMetadata.technical)
      .map(this.reportFilterMetadataToAdvancedTableFilterDefinition)
  }

  reportFilterMetadataToAdvancedTableFilterDefinition = (
    filterMetadata: StwReportFilterMetadata
  ): AdvancedTableFilter => {
    switch (filterMetadata.type) {
      case AdvancedTableFilterKind.ENUM:
        return {
          key: filterMetadata.parameter,
          type: filterMetadata.type,
          column: filterMetadata.columnParameter,
          label: __(`fields.labels.${filterMetadata.parameter}`, filterMetadata.entityAttribute),
          select: { option: filterMetadata.parameter },
          col: 8,
        }
      case AdvancedTableFilterKind.AUTOCOMPLETE:
        return {
          key: filterMetadata.parameter,
          type: filterMetadata.type,
          column: filterMetadata.columnParameter,
          label: __(`fields.labels.${filterMetadata.parameter}`, filterMetadata.entityAttribute),
          autocomplete: {
            endpoint: filterMetadata.endpoint!,
            sort: filterMetadata.sort!,
            multiple: filterMetadata.multiple!,
          },
          option: filterMetadata.parameter,
          col: 8,
        }
      case AdvancedTableFilterKind.STRING_AUTOCOMPLETE:
        return {
          key: filterMetadata.parameter,
          type: filterMetadata.type,
          column: filterMetadata.columnParameter,
          label: __(`fields.labels.${filterMetadata.parameter}`, filterMetadata.entityAttribute),
          autocomplete: {
            endpoint: filterMetadata.endpoint!,
            sort: filterMetadata.sort!,
            multiple: filterMetadata.multiple!,
            additionalParams: filterMetadata.additionalParams || {},
          },
          option: filterMetadata.parameter,
          col: 8,
          maxTagCount: 2,
        }
      case AdvancedTableFilterKind.STRING:
      case AdvancedTableFilterKind.DATE_RANGE:
      case AdvancedTableFilterKind.STRING_LIST:
      default:
        return {
          key: filterMetadata.parameter,
          type: filterMetadata.type,
          column: filterMetadata.columnParameter,
          label: __(`fields.labels.${filterMetadata.parameter}`, filterMetadata.entityAttribute),
          col: 8,
        }
    }
  }

  getFilterParameters = (
    filtersMetadata: ReadonlyArray<StwReportFilterMetadata>
  ): {
    [key: string]: object
  } => {
    const booleanParams = {
      enabledValues: BooleanComboValues,
    }

    const enumParams = filtersMetadata
      .filter((filterMetadata) => filterMetadata.type === AdvancedTableFilterKind.ENUM)
      .reduce(
        (filterParameters, filterMetadata) => ({
          ...filterParameters,
          [filterMetadata.parameter]: filterMetadata.values?.map((filterValue) => ({
            id: filterValue,
            description: __(`enum.${filterMetadata.parameter}.${filterValue}`, filterValue),
          })),
        }),
        booleanParams
      )

    return filtersMetadata
      .filter((filterMetadata) => filterMetadata.type === AdvancedTableFilterKind.AUTOCOMPLETE)
      .reduce(
        (filterParameters, filterMetadata) => ({
          ...filterParameters,
          [filterMetadata.parameter]: filterMetadata.additionalParams,
        }),
        enumParams
      )
  }

  componentDidUpdate = (prevProps) => {
    const { reportId, operationType, operationId } = this.props
    if (
      prevProps.reportId !== reportId ||
      prevProps.operationType !== operationType ||
      prevProps.operationId !== operationId
    ) {
      this._init()
    }
    return true
  }

  render() {
    const { tableConfig, title, report } = this.state
    const {
      actions,
      operationId,
      tabs,
      disabledReportExport,
      deleteRecord,
      headerType,
      reportParams,
      sortValues,
      numberRecodPage,
      titleEmpty,
    } = this.props
    return (
      <TableList
        title={title}
        titleEmpty={titleEmpty}
        tabs={tabs}
        location={this.props.location}
        config={tableConfig}
        operationId={operationId}
        resource={{
          call: Reports.reportData,
          endpoint: `${Reports.endpoint}/${report}`,
          filters: { operationId: operationId },
        }}
        reportId={report}
        enabledReportExport={!disabledReportExport}
        actions={actions}
        deleteRecord={deleteRecord}
        headerType={headerType}
        reportParams={reportParams}
        sortValues={sortValues}
        numberRecodPage={numberRecodPage}
      />
    )
  }
}
