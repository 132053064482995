import { StwFullRfidAntenna, StwFullRfidReaderForm, StwRfidReaderAntennas, StwRfidReaderType } from '../../api'

export function getAntennaByPort(port: number, antennas: StwFullRfidAntenna[]) {
  return antennas.find((antenna) => antenna.readerPort === port)
}

export function mapRfidReaderToFull(reader: StwRfidReaderAntennas): StwFullRfidReaderForm {
  const full: StwFullRfidReaderForm = {}
  if (reader) {
    full.id = reader.id
    full.creationDate = reader.creationDate
    full.lastModifyDate = reader.lastModifyDate
    full.code = reader.code
    full.description = reader.description
    full.place = reader.place
    full.deviceType = reader.deviceType
    full.ports = reader.ports
    full.settings = reader.settings
    full.totalAntennas = reader.antennas ? reader.antennas.length : 0
    full.antenna1 = getAntennaByPort(1, reader.antennas || [])
    full.antenna2 = getAntennaByPort(2, reader.antennas || [])
    full.antenna3 = getAntennaByPort(3, reader.antennas || [])
    full.antenna4 = getAntennaByPort(4, reader.antennas || [])
    full.antenna5 = getAntennaByPort(5, reader.antennas || [])
    full.antenna6 = getAntennaByPort(6, reader.antennas || [])
    full.antenna7 = getAntennaByPort(7, reader.antennas || [])
    full.antenna8 = getAntennaByPort(8, reader.antennas || [])

    if (reader.deviceType && reader.settings) {
      switch (reader.deviceType) {
        case StwRfidReaderType.CAEN_HEX:
        case StwRfidReaderType.NORDIC_ID_SAMPO_NATIVE:
          full.connectionMode = reader.settings.connectionMode
          full.ipAddress = reader.settings.ipAddress
          full.maxConnectionTimeout = reader.settings.maxConnectionTimeout
          break
        case StwRfidReaderType.NORDIC_ID_SAMPO_LABID:
          full.maxConnectionTimeout = reader.settings.maxConnectionTimeout
          full.ipAddress = reader.settings.ipAddress
          break
        case StwRfidReaderType.IMPINJ_SPEEDWAY_OCTANE:
          full.maxConnectionTimeout = reader.settings.maxConnectionTimeout
          full.ipAddress = reader.settings.ipAddress
          full.readerMode = reader.settings.readerMode
          full.searchMode = reader.settings.searchMode
          full.session = reader.settings.session || ''
          full.keepAliveEnabled = convertStringToBoolean(reader.settings.keepAliveEnabled)
          full.keepAlivePeriod = reader.settings.keepAlivePeriod
          full.linkMonitorModeEnabled = convertStringToBoolean(reader.settings.linkMonitorModeEnabled)
          full.linkDownThreshold = reader.settings.linkDownThreshold
          full.triggerEnabled = convertStringToBoolean(reader.settings.triggerEnabled)
          full.timeoutEnabled = convertStringToBoolean(reader.settings.timeoutEnabled)
          full.startType = reader.settings.startType
          full.gpioStartPort = reader.settings.gpioStartPort
          full.stopType = reader.settings.stopType
          full.gpioStopPort = reader.settings.gpioStopPort
          full.debounceTime = reader.settings.debounceTime
          full.timeout = reader.settings.timeout

          if (full.triggerEnabled) {
            full.triggerTimeoutSelect = 'triggerEnabled'
          } else if (full.timeoutEnabled) {
            full.triggerTimeoutSelect = 'timeoutEnabled'
          }
          break
        case StwRfidReaderType.RFID_EMULATOR:
          full.emulationMode = reader.settings.emulationMode
          full.epcs = reader.settings.epcs
          full.minEpcDelay = reader.settings.minEpcDelay
          full.maxEpcDelay = reader.settings.maxEpcDelay
          break
        case StwRfidReaderType.CAEN_QUATTRO:
          full.connectionMode = reader.settings.connectionMode
          full.ipAddress = reader.settings.ipAddress || ''
          full.maxConnectionTimeout = reader.settings.maxConnectionTimeout
          full.memoryBank = reader.settings.memoryBank
          full.socketPort = reader.settings.socketPort
          full.gpioPolling = reader.settings.gpioPolling
          full.triggerEnabled = convertStringToBoolean(reader.settings.triggerEnabled)
          full.timeoutEnabled = convertStringToBoolean(reader.settings.timeoutEnabled)
          full.startType = reader.settings.startType
          full.gpioStartPort = reader.settings.gpioStartPort
          full.stopType = reader.settings.stopType
          full.gpioStopPort = reader.settings.gpioStopPort
          full.debounceTime = reader.settings.debounceTime
          full.timeout = reader.settings.timeout
          full.loginUsername = reader.settings.loginUsername
          full.loginPassword = reader.settings.loginPassword

          if (full.triggerEnabled) {
            full.triggerTimeoutSelect = 'triggerEnabled'
          } else if (full.timeoutEnabled) {
            full.triggerTimeoutSelect = 'timeoutEnabled'
          }
          break
        case StwRfidReaderType.ZEBRA_FX_SERIES:
          full.ipAddress = reader.settings.ipAddress || ''
          full.maxConnectionTimeout = reader.settings.maxConnectionTimeout
          full.socketPort = reader.settings.socketPort
          full.rxSensitivity = reader.settings.rxSensitivity
          full.triggerEnabled = convertStringToBoolean(reader.settings.triggerEnabled)
          full.timeoutEnabled = convertStringToBoolean(reader.settings.timeoutEnabled)
          full.startType = reader.settings.startType
          full.gpioStartPort = reader.settings.gpioStartPort
          full.stopType = reader.settings.stopType
          full.gpioStopPort = reader.settings.gpioStopPort
          full.debounceTime = reader.settings.debounceTime
          full.timeout = reader.settings.timeout
          full.loginUsername = reader.settings.loginUsername
          full.loginPassword = reader.settings.loginPassword

          if (full.triggerEnabled) {
            full.triggerTimeoutSelect = 'triggerEnabled'
          } else if (full.timeoutEnabled) {
            full.triggerTimeoutSelect = 'timeoutEnabled'
          }
          break
      }
    }
  }
  return full
}

export function mapFullToRfidReader(full: StwFullRfidReaderForm): StwRfidReaderAntennas {
  const reader: StwRfidReaderAntennas = {}
  if (reader) {
    reader.id = full.id
    reader.creationDate = full.creationDate
    reader.lastModifyDate = full.lastModifyDate
    reader.code = full.code
    reader.description = full.description
    reader.place = full.place
    reader.deviceType = full.deviceType
    reader.ports = full.ports
    reader.settings = {}

    if (reader.deviceType && full) {
      switch (reader.deviceType) {
        case StwRfidReaderType.CAEN_HEX:
        case StwRfidReaderType.NORDIC_ID_SAMPO_NATIVE:
          reader.settings.connectionMode = full.connectionMode
          reader.settings.ipAddress = full.ipAddress
          reader.settings.maxConnectionTimeout = full.maxConnectionTimeout
          break
        case StwRfidReaderType.NORDIC_ID_SAMPO_LABID:
          reader.settings.maxConnectionTimeout = full.maxConnectionTimeout
          reader.settings.ipAddress = full.ipAddress
          break
        case StwRfidReaderType.IMPINJ_SPEEDWAY_OCTANE:
          reader.settings.maxConnectionTimeout = full.maxConnectionTimeout
          reader.settings.ipAddress = full.ipAddress
          reader.settings.readerMode = full.readerMode
          reader.settings.searchMode = full.searchMode
          reader.settings.session = full.session
          reader.settings.keepAliveEnabled = full.keepAliveEnabled
          reader.settings.keepAlivePeriod = full.keepAlivePeriod
          reader.settings.linkMonitorModeEnabled = full.linkMonitorModeEnabled
          reader.settings.linkDownThreshold = full.linkDownThreshold
          reader.settings.triggerEnabled = full.triggerEnabled
          reader.settings.timeoutEnabled = full.timeoutEnabled
          reader.settings.startType = full.startType
          reader.settings.gpioStartPort = full.gpioStartPort
          reader.settings.stopType = full.stopType
          reader.settings.gpioStopPort = full.gpioStopPort
          reader.settings.debounceTime = full.debounceTime
          reader.settings.timeout = full.timeout
          break
        case StwRfidReaderType.RFID_EMULATOR:
          reader.settings.emulationMode = full.emulationMode
          reader.settings.epcs = full.epcs
          reader.settings.minEpcDelay = full.minEpcDelay
          reader.settings.maxEpcDelay = full.maxEpcDelay
          break
        case StwRfidReaderType.CAEN_QUATTRO:
          reader.settings.connectionMode = full.connectionMode
          reader.settings.ipAddress = full.ipAddress
          reader.settings.maxConnectionTimeout = full.maxConnectionTimeout
          reader.settings.memoryBank = full.memoryBank
          reader.settings.socketPort = full.socketPort
          reader.settings.gpioPolling = full.gpioPolling
          reader.settings.triggerEnabled = full.triggerEnabled
          reader.settings.timeoutEnabled = full.timeoutEnabled
          reader.settings.startType = full.startType
          reader.settings.gpioStartPort = full.gpioStartPort
          reader.settings.stopType = full.stopType
          reader.settings.gpioStopPort = full.gpioStopPort
          reader.settings.debounceTime = full.debounceTime
          reader.settings.timeout = full.timeout
          reader.settings.loginUsername = full.loginUsername
          reader.settings.loginPassword = full.loginPassword
          break
        case StwRfidReaderType.ZEBRA_FX_SERIES:
          reader.settings.ipAddress = full.ipAddress
          reader.settings.maxConnectionTimeout = full.maxConnectionTimeout
          reader.settings.socketPort = full.socketPort
          reader.settings.rxSensitivity = full.rxSensitivity
          reader.settings.triggerEnabled = full.triggerEnabled
          reader.settings.timeoutEnabled = full.timeoutEnabled
          reader.settings.startType = full.startType
          reader.settings.gpioStartPort = full.gpioStartPort
          reader.settings.stopType = full.stopType
          reader.settings.gpioStopPort = full.gpioStopPort
          reader.settings.debounceTime = full.debounceTime
          reader.settings.timeout = full.timeout
          reader.settings.loginUsername = full.loginUsername
          reader.settings.loginPassword = full.loginPassword
          break
      }
      reader.antennas = []
      if (full.antenna1 && full.antenna1.readerPort) reader.antennas.push(full.antenna1)
      if (full.antenna2 && full.antenna2.readerPort) reader.antennas.push(full.antenna2)
      if (full.antenna3 && full.antenna3.readerPort) reader.antennas.push(full.antenna3)
      if (full.antenna4 && full.antenna4.readerPort) reader.antennas.push(full.antenna4)
      if (full.antenna5 && full.antenna5.readerPort) reader.antennas.push(full.antenna5)
      if (full.antenna6 && full.antenna6.readerPort) reader.antennas.push(full.antenna6)
      if (full.antenna7 && full.antenna7.readerPort) reader.antennas.push(full.antenna7)
      if (full.antenna8 && full.antenna8.readerPort) reader.antennas.push(full.antenna8)
    }
  }
  return reader
}

function convertStringToBoolean(value: string) {
  return value ? value.toLowerCase() === 'true' : false
}
