import { Component, ReactNode } from 'react'
import { Button } from 'antd'
import { SearchOutlined, DeleteOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim'
import { SorterResult } from 'antd/lib/table/interface'
import {
  Section,
  TableListHeader,
  AdvancedTableRowColumn,
  AdvancedTableRowColumnUserPlaces,
  ModalContentList,
  ModalConfirm,
  ModalAddAttribute,
} from '..'
import { AdvancedTableExportModal } from '../AdvancedTableExportModal'
import { DEFAULT_PAGE_SIZE, ENABLED_TABLE_COLUMN_FILTER } from '../../constants'
import { refresh } from '../../shared/router'
import { ExtendRouteComponentProps } from '../../types'
import {
  AdvancedTable,
  AdvancedTableColumn,
  buildPagination,
  buildQueryParamsOnFilterChange,
  buildQueryParamsOnPaginationChange,
  AdvancedTableColumnFilter,
  buildApiParams,
  convertAntdSortsToAdvancedTableSorts,
  advancedTableFilterValuesToQueryParameters,
  advancedTableSortValuesToQueryParameters,
} from '../AdvancedTable'
import { AttributeUtil } from '../../config/utility/utility'
import {
  AdvancedTableColumns,
  AdvancedTableConfig,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
} from '../AdvancedTable/advancedTableTypes'
import {
  getTableColumnsWithAttributesMapping,
  refreshFixedColumns,
  getFilterApplied,
  getQueryParamFromObject,
} from '../../shared/utils'
import ColumnsStorage from '../../shared/ColumnsStorage'
import { StylewhereTab } from './Tabs'
import Reports from '../../api/Reports'
import { __, T } from '../../shared/i18n'
import { getEditActionsTableColumns, getActionsTableColumns } from '../../config/utility/capabilityUtility'
import { StwAttribute } from '../../api'
import { AdvancedFormInputType } from '../AdvancedForm/advancedFormTypes'
import AttributeMappings from '../../api/AttributeMappings'

export interface TableState {
  tableColumns: AdvancedTableColumn[]
  baseTableColumns: AdvancedTableColumn[]
  tableContent: any[]
  totalElements: number
  tablePagination: AdvancedTablePagination
  loader: boolean
  firstLoad: boolean
  visibleManagerColumns: boolean
  filterOpened: boolean
  exportModalVisible: boolean
  modalDetailVisible: boolean
  modalDetailList: any[]
  modalVisible: boolean
  deleteRecordId: string
  modalAttibuteVisible: boolean
  attribute: StwAttribute | undefined
  editAttribute: boolean
  lastEndpoint: string
}

interface TableProps extends ExtendRouteComponentProps {
  title?: string
  titleEmpty?: string
  config: AdvancedTableConfig
  actions?: ReadonlyArray<ReactNode>
  headerType?: 'paged' | 'boxed' | 'unset'
  resource: { call: (...args: any[]) => any; endpoint: string; filters?: any }
  tabs?: StylewhereTab[]
  reportId?: string
  operationId?: string
  enabledReportExport?: boolean
  deleteRecord?: any
  forceDataUpdate?: number
  defaultPagination?: AdvancedTablePagination
  reportParams?: any
  sortValues?: any
  numberRecodPage?: number
  addAttribute?: any
  disabledMaxHeightPanel?: boolean
  isEmptyContentCallback?: (elements: number) => void
}

export class TableList extends Component<TableProps, TableState> {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [],
      baseTableColumns: [],
      tableContent: [],
      totalElements: 0,
      tablePagination: {
        filterValues: undefined,
        pageSize: DEFAULT_PAGE_SIZE,
        sortValues: {},
        pageNumber: 1,
      },
      loader: true,
      firstLoad: true,
      visibleManagerColumns: false,
      filterOpened: false,
      exportModalVisible: false,
      modalDetailVisible: false,
      modalDetailList: [],
      modalVisible: false,
      deleteRecordId: '',
      modalAttibuteVisible: false,
      attribute: undefined,
      editAttribute: false,
      lastEndpoint: '',
    }
  }

  componentDidMount() {
    this._init()
  }

  getRecordTableId = (record, objAction) => {
    if (objAction.fieldId) return record[objAction.fieldId] || 'undefined'
    return record.id
  }

  getRecordActions = (objAction, record) => {
    const { deleteRecord } = this.props
    let actions = getEditActionsTableColumns(
      this.getRecordTableId(record, objAction.reportActions),
      `${objAction.basePath}`
    )
    if (objAction.reportActions) {
      if (objAction.reportActions.isAttribute) {
        actions = [{ content: <EyeOutlined />, type: 'default', onclick: () => this.editAttribute(record.id) }]
      } else if (!objAction.reportActions.onlyedit) {
        actions = getActionsTableColumns(
          this.getRecordTableId(record, objAction.reportActions),
          `${objAction.basePath}`,
          objAction.reportActions.editCapabilites || '',
          objAction.reportActions.canDelete && deleteRecord && deleteRecord.call,
          this.deleteRecord
        )
      }
    }
    return actions
  }

  _init = async () => {
    const { config, defaultPagination, sortValues, numberRecodPage, resource } = this.props
    if (!config.columns || config.columns.length === 0) {
      return
    }
    const cols: AdvancedTableColumn[] = await getTableColumnsWithAttributesMapping(
      config.columns || [],
      config.entity || '',
      config.mappingAttributeIndex || 3
    )
    /* 
    si controlla se le actions hanno un render altrimenti si aggiunge
    */
    const objAction = cols.find((element) => {
      return element.key === 'action'
    })
    if (objAction && !objAction.render) {
      objAction.render = (text, record) => <AdvancedTableRowColumn actions={this.getRecordActions(objAction, record)} />
    }
    if (ENABLED_TABLE_COLUMN_FILTER && !config.disableColumnFilters) {
      for (let c = 0; c < cols.length; c++) {
        const obj: any = config.filterDefinitions.find((filter) => {
          return filter.column === cols[c].key
        })
        if (obj) {
          cols[c] = {
            ...cols[c],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <AdvancedTableColumnFilter
                filter={obj}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                pagination={this.state.tablePagination}
                onFilter={this.changeFilterColumn}
                className={cols[c].className || 'stw-medium'}
                filterParameters={config.filterParameters}
              />
            ),
            filterIcon: <SearchOutlined style={{ color: '#1890ff' }} />,
          }
        }
      }
    }
    const storageCols: AdvancedTableColumn[] = await this.getStorageColumns(config.manageColumnsPrefix, cols)
    /* per users
     * andrebbe gestita inserendo un nuovo tipo di colonna direttamente nel database
     */
    storageCols
      .filter((col) => col.key === 'userPlaces')
      .forEach((col) => {
        col.render = (text, record, index) => {
          return (
            <AdvancedTableRowColumnUserPlaces
              key={`user_places_${index}`}
              record={record}
              showDetail={this.showDetail}
            />
          )
        }
      })

    const pag: AdvancedTablePagination = defaultPagination || {
      filterValues: undefined,
      pageSize: DEFAULT_PAGE_SIZE,
      sortValues: {},
      pageNumber: 1,
    }
    if (sortValues) {
      pag.sortValues = sortValues
    }
    if (numberRecodPage) {
      pag.pageSize = numberRecodPage
    }
    this.setState(
      {
        tableColumns: storageCols,
        baseTableColumns: cols,
        tableContent: [],
        totalElements: 0,
        tablePagination: pag,
        lastEndpoint: resource.endpoint,
      },
      this.getTableDataContent
    )
  }

  getTableDataContent = async () => {
    const { config, location, resource, reportParams, isEmptyContentCallback } = this.props
    let paging
    if (config.disableLocation) {
      paging = this.state.tablePagination
    } else {
      paging = buildPagination(location, config.filterDefinitions, this.state.tableColumns)
    }
    const params = buildApiParams(paging, config)
    if (resource.filters) {
      const keys = Object.keys(resource.filters)
      for (let k = 0; k < keys.length; k++) {
        if (resource.filters[keys[k]]) {
          params[keys[k]] = resource.filters[keys[k]]
        }
      }
    }

    if (reportParams) {
      Object.keys(reportParams).forEach((key) => {
        params[key] = reportParams[key]
      })
    }
    const result = await resource.call(resource.endpoint, params)
    //console.log(result)
    if (this.state.lastEndpoint === resource.endpoint) {
      if (this.state.firstLoad && isEmptyContentCallback) {
        isEmptyContentCallback(result && result.content ? result.content.length : 0)
      }
      this.setState({
        tableContent: result && result.content ? result.content : [],
        totalElements: result && result.content ? result.totalElements : 0,
        tablePagination: paging,
        loader: false,
        firstLoad: false,
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<TableProps>) {
    const { location, config, forceDataUpdate } = this.props
    if (
      location.search !== prevProps.location.search &&
      location.search.indexOf('modal') === -1 &&
      prevProps.location.search.indexOf('modal') === -1
    ) {
      this.changeLocationSearch()
    } else if (JSON.stringify(prevProps.config) !== JSON.stringify(config)) {
      this.refreshData()
    } else if (!this.state.loader && JSON.stringify(prevProps.config.columns) !== JSON.stringify(config.columns)) {
      this._init()
    } else if (prevProps.forceDataUpdate !== forceDataUpdate) {
      this.refreshDataContent()
    }
  }

  refreshDataContent = () => {
    this.setState(
      {
        loader: true,
      },
      this.getTableDataContent
    )
  }

  refreshData = () => {
    this.setState(
      {
        loader: true,
        firstLoad: true,
        lastEndpoint: '',
        tableColumns: [],
        baseTableColumns: [],
        tableContent: [],
        totalElements: 0,
        tablePagination: {
          filterValues: undefined,
          pageSize: DEFAULT_PAGE_SIZE,
          sortValues: {},
          pageNumber: 1,
        },
      },
      this._init
    )
  }

  changeLocationSearch = () => {
    this.setState({ loader: true }, this.getTableDataContent)
  }

  reloadData = () => {
    this.changeLocationSearch()
  }

  paginationChangeHandler = (pagination, filters, sorts: SorterResult<any> | SorterResult<any>[]) => {
    const { config, location } = this.props
    if (config.disableLocation) {
      this.setState(
        {
          loader: true,
          tablePagination: {
            ...this.state.tablePagination,
            pageSize: pagination.pageSize!,
            pageNumber: pagination.current,
            sortValues: convertAntdSortsToAdvancedTableSorts(sorts)!,
          },
        },
        this.getTableDataContent
      )
    } else {
      refresh(buildQueryParamsOnPaginationChange(location, config, pagination, sorts))
    }
  }

  filtersChangeHandler(filterKey: string, filterValue: any) {
    const { tablePagination } = this.state
    AttributeUtil.setAttribute(tablePagination.filterValues, filterKey, filterValue)
    this.setState({ tablePagination: tablePagination })
  }

  filtersAppliedHandler(filterValues: AdvancedTableFilterValues) {
    const tmp = this.state.tablePagination
    tmp.filterValues = filterValues
    this.setState({ tablePagination: tmp }, this.applySearch)
  }

  applySearch = () => {
    const { config, location } = this.props
    const { tablePagination } = this.state
    if (tablePagination.filterValues) {
      if (config.disableLocation) {
        this.setState({ loader: true }, this.getTableDataContent)
      } else {
        refresh(buildQueryParamsOnFilterChange(location, tablePagination.filterValues, config))
      }
    }
  }

  managementColumns = () => {
    this.setState({ visibleManagerColumns: !this.state.visibleManagerColumns })
  }

  refreshTableColumns = async (columns) => {
    this.setState(
      {
        tableColumns: refreshFixedColumns(columns),
      },
      this.managementColumns
    )
  }

  getStorageColumns = async (
    manageColumnsPrefix: string | undefined,
    columns: AdvancedTableColumn[] | AdvancedTableColumns
  ): Promise<AdvancedTableColumn[]> => {
    if (manageColumnsPrefix) {
      return ColumnsStorage.load(manageColumnsPrefix, columns)
    }
    return Promise.all(columns)
  }

  changeFilterColumn = (filterKey: string, filterValue: any) => {
    const { tablePagination } = this.state
    AttributeUtil.setAttribute(tablePagination.filterValues, filterKey, filterValue)
    this.setState({ tablePagination: tablePagination }, this.applySearch)
  }

  filterPanelOpened = (status) => {
    this.setState({ filterOpened: status })
  }

  showExportModal = () => this.setState({ exportModalVisible: true })

  hideExportModal = () => this.setState({ exportModalVisible: false })

  getExportModalEndpoint = () => {
    const { config, reportId, reportParams, operationId } = this.props
    const params = getQueryParamFromObject(
      advancedTableFilterValuesToQueryParameters(config.filterDefinitions, this.state.tablePagination.filterValues)
    )

    const sort = advancedTableSortValuesToQueryParameters(this.state.tablePagination.sortValues)
    let constantParams = ''
    if (reportParams) {
      Object.keys(reportParams).forEach((key) => {
        constantParams += `${key}=${reportParams[key]}`
      })
    }

    let paramsExport = params
    if (constantParams !== '') paramsExport += (paramsExport !== '' ? '&' : '') + constantParams
    paramsExport += `${paramsExport !== '' ? '&' : ''}sort=${sort}`

    let endpoint = `${Reports.endpoint}/${reportId}/execute?${paramsExport}`
    if (operationId) endpoint += `&operationId=${operationId}`
    return endpoint
  }

  getHeaderActions = () => {
    const { actions, enabledReportExport, addAttribute } = this.props
    const tmp: any = []
    if (addAttribute && addAttribute.hasCapabilites) {
      tmp.push(
        <Button className="stylewhere-button-primary" onClick={() => this.addAttribute()} key="1">
          <PlusCircleOutlined /> {__(T.misc.addAttribute)}
        </Button>
      )
    }
    if (enabledReportExport && this.state.totalElements > 0) {
      tmp.push(
        <Button className="stylewhere-button-secondary" onClick={this.showExportModal} key="1">
          {__(T.misc.export)}
        </Button>
      )
    }
    if (actions && actions.length > 0) {
      return tmp.concat(actions)
    }
    return tmp
  }

  showDetail = (userPlaces) => {
    this.setState({
      modalDetailVisible: true,
      modalDetailList: userPlaces,
    })
  }

  hideDetail = () => {
    this.setState({
      modalDetailVisible: false,
    })
  }

  deleteRecord = (id) => {
    this.setState({
      modalVisible: true,
      deleteRecordId: id,
    })
  }

  confirmDeleteRecord = async (val) => {
    const { deleteRecordId } = this.state
    const { deleteRecord } = this.props
    if (val) {
      await deleteRecord.call(deleteRecord.endpoint, deleteRecordId)
      this.setState(
        {
          modalVisible: false,
          deleteRecordId: '',
          tableContent: [],
          loader: true,
        },
        this.getTableDataContent
      )
    } else {
      this.setState({
        modalVisible: false,
        deleteRecordId: '',
      })
    }
  }

  getHeadeType = () => {
    const { headerType, config } = this.props
    const { totalElements } = this.state
    let type = headerType || 'paged'
    if (config.hiddenEmptyTable && totalElements === 0) {
      type = 'paged'
    }
    return type
  }

  getKeyColumn = (key, acceptDuplicate, type, rules: any, option = '', editable = true) => {
    return {
      key: key,
      field: key,
      label: __(`misc.${key}`),
      error: __(`misc.${key}Required`),
      placeholder:
        type === AdvancedFormInputType.SELECT ? __(`fields.placeholders.chooseCode`) : __(`fields.placeholders.${key}`),
      type: type,
      checkDuplicate: !acceptDuplicate,
      duplicate: __(`misc.${key}Duplicate`),
      option: option,
      editable: editable,
      rules: rules,
      rows: 4,
    }
  }

  addAttribute = () => {
    const { addAttribute } = this.props
    if (addAttribute) {
      this.setState({
        attribute: {
          entity: addAttribute.selectedEntity.value,
          code: undefined,
          description: undefined,
          defaultValue: undefined,
        },
        editAttribute: false,
        modalAttibuteVisible: true,
      })
    }
  }

  editAttribute = (id) => {
    const { tableContent } = this.state
    const find = tableContent.find((element) => element.id === id)
    if (find) {
      this.setState({
        attribute: find,
        editAttribute: true,
        modalAttibuteVisible: true,
      })
    }
  }

  confirmAttribute = (attribute) => {
    this.setState(
      {
        attribute: attribute,
        modalAttibuteVisible: false,
      },
      this.saveAttribute
    )
  }

  saveAttribute = () => {
    const { editAttribute, attribute } = this.state
    if (editAttribute) {
      AttributeMappings.update<StwAttribute>(attribute).then(() => {
        this.reloadData()
      })
    } else {
      AttributeMappings.insert<StwAttribute>(attribute).then((newAttribute) => {
        if (newAttribute && newAttribute.id) {
          this.reloadData()
        }
      })
    }
  }

  closeModalAttribute = () => {
    this.setState(
      {
        modalAttibuteVisible: false,
      },
      this.resetAttribute
    )
  }

  resetAttribute = () => {
    setTimeout(() => {
      this.setState({
        attribute: undefined,
        editAttribute: false,
      })
    }, 500)
  }

  render() {
    const { config, title, titleEmpty, tabs, reportId, deleteRecord, addAttribute } = this.props
    const {
      tableColumns,
      baseTableColumns,
      tableContent,
      totalElements,
      visibleManagerColumns,
      tablePagination,
      loader,
      firstLoad,
      filterOpened,
      exportModalVisible,
      modalDetailVisible,
      modalDetailList,
      modalVisible,
      modalAttibuteVisible,
      attribute,
      editAttribute,
    } = this.state
    const filterApplied = getFilterApplied(tablePagination, config.filterDefinitions)
    const type = this.getHeadeType()
    return (
      <>
        {type !== 'unset' && (
          <TableListHeader
            title={totalElements === 0 && titleEmpty ? titleEmpty : title}
            tabs={tabs}
            actions={this.getHeaderActions()}
            visibleManagerColumns={visibleManagerColumns}
            managementColumns={config.disableManagerColumns || totalElements === 0 ? undefined : this.managementColumns}
            manageColumnsPrefix={config.manageColumnsPrefix}
            refreshTableColumns={this.refreshTableColumns}
            columns={config.manageColumnsPrefix ? baseTableColumns : tableColumns}
            filters={totalElements === 0 && filterApplied === 0 ? undefined : config}
            onFiltersChanged={(filterKey, filterValue) => this.filtersChangeHandler(filterKey, filterValue)}
            onFiltersApplied={(appliedFilterValues) => this.filtersAppliedHandler(appliedFilterValues)}
            pagination={tablePagination}
            filterApplied={filterApplied}
            filterOpened={filterOpened}
            filterPanelOpened={this.filterPanelOpened}
            skeleton={{ active: loader && firstLoad, options: config.skeletonOptions || { createBtn: true } }}
            headerType={type}
            disabledMaxHeightPanel={type === 'boxed'}
          />
        )}
        {(loader || !config.hiddenEmptyTable || (config.hiddenEmptyTable && totalElements > 0)) && (
          <Section
            customClass={`paged-header ${filterApplied > 0 ? 'filtered' : ''} ${type || ''} 
              ${config.disabledScroll || config.fixedTableHeight ? 'noheight' : ''}
              ${config.tableBorder ? 'stw-border' : ''}
            `}
          >
            <AdvancedTable
              columns={tableColumns}
              pagination={tablePagination}
              content={tableContent}
              totalElements={totalElements}
              onTableChanged={(pageConfig, filters, sorts) => this.paginationChangeHandler(pageConfig, filters, sorts)}
              disabledScroll={config.disabledScroll}
              filterApplied={filterApplied}
              loader={loader}
              activeDefaultClassColumn={config.activeDefaultClassColumn}
              reloadData={config.disabledReloadData ? undefined : this.reloadData}
              disabledNoDataFound={config.disabledNoDataFound}
              fixedTableHeight={config.fixedTableHeight}
            />
            <QueueAnim duration={300}>
              {filterOpened ? <div key="tabl-opacity" className="stw-table-opacity" /> : null}
            </QueueAnim>
          </Section>
        )}
        {exportModalVisible && reportId && (
          <AdvancedTableExportModal
            title={config.exportModalTitle || `${__(`report.${reportId}`)}`}
            filenamePrefix={config.exportModalFilenamePrefix || 'export'}
            endpoint={this.getExportModalEndpoint()}
            columns={tableColumns}
            visible={exportModalVisible}
            hide={this.hideExportModal}
          />
        )}
        <ModalContentList
          visible={modalDetailVisible}
          title={__('fields.labels.userPlaces')}
          attributeCode="code"
          attributeDescription="description"
          entities={modalDetailList}
          callback={() => this.hideDetail()}
        />
        {deleteRecord && deleteRecord.call && (
          <ModalConfirm
            visible={modalVisible}
            icon={<DeleteOutlined style={{ fontSize: 46 }} />}
            title={deleteRecord.title}
            subtitle={deleteRecord.subtitle}
            callback={this.confirmDeleteRecord}
          />
        )}
        {addAttribute && addAttribute.selectedEntity && attribute && (
          <ModalAddAttribute
            visible={modalAttibuteVisible}
            attributes={tableContent}
            title={editAttribute ? __(`misc.modal.attributes.edit`) : __(`misc.modal.attributes.add`)}
            attribute={attribute}
            close={this.closeModalAttribute}
            confirm={this.confirmAttribute}
            selectedEntity={addAttribute.selectedEntity}
            configuration={[
              this.getKeyColumn(
                'code',
                false,
                addAttribute.selectedEntity.options
                  ? AdvancedFormInputType.SELECT_AUTOCOMPLETE
                  : AdvancedFormInputType.TEXT,
                [
                  {
                    required: true,
                    /*type: 'string',
                    pattern: new RegExp(/^[a-z][A-Za-z0-9]*$/),
                    message: __(T.misc.camelCaseCode),*/
                  },
                ],
                addAttribute.selectedEntity.options ? 'optionsCode' : '',
                !editAttribute
              ),
              this.getKeyColumn('defaultValue', true, AdvancedFormInputType.TEXT, [{ required: false }]),
              this.getKeyColumn('description', true, AdvancedFormInputType.TEXTAREA, [{ required: false }]),
            ]}
            edit={editAttribute}
          />
        )}
      </>
    )
  }
}
