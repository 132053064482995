import { Component } from 'react'
import { Col, Row, Table, TablePaginationConfig } from 'antd'
import { SorterResult, SortOrder } from 'antd/lib/table/interface'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { AdvancedTableRowColumn } from '../AdvancedTableRowColumn'
import { __, T } from '../../shared/i18n'
import { advancedTableSortDirectionToSortOrder } from './advancedTableSort'
import {
  AdvancedTableColumn,
  AdvancedTableColumns,
  AdvancedTableConfig,
  AdvancedTableContentKind,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
  AdvancedTableSortDirection,
  AdvancedTableSortValues,
} from './advancedTableTypes'
import { NotFound, SkeletonTable, TableReloadButton, TableNumberPage, SectionTitle } from '..'
import { getTagColor } from '../../shared/utils'

export interface PaginationRecordData {
  active: boolean
  from: number
  to: number
  total: number
}

interface Props {
  columns: AdvancedTableColumns
  content: readonly any[]
  totalElements: number
  filters?: AdvancedTableConfig
  pagination: AdvancedTablePagination
  onTableChanged?: (pagination: TablePaginationConfig, filters, sorts: SorterResult<any> | SorterResult<any>[]) => void
  refreshTableColumns?: (...args: any[]) => any
  border?: boolean
  disabledScroll?: boolean
  filterApplied?: number
  loader?: boolean
  activeDefaultClassColumn?: boolean
  fixedTableHeight?: boolean
  reloadData?: () => void
  disabledPagination?: boolean
  disabledNoDataFound?: boolean
  tableTitle?: { title: string; emptyTitle: string }
  /* da rimuovere */
  filtersChangedHandler?: (filterKey: string, filterValue: any) => void //non usato
  filtersAppliedHandler?: (filterValues: AdvancedTableFilterValues) => void //non usato
  enabledNumberData?: boolean //non dovrebbe essere usato
  managementColumns?: (...args: any[]) => any //non usato
  visibleFiltersForm?: boolean //non usato
  visibleManagerColumns?: boolean //non usato
  managementFilters?: (...args: any[]) => any //non usato
}

interface State {
  tableHeight: number
}

export class AdvancedTable extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      tableHeight: 0,
    }
  }

  componentDidMount() {
    this._init()
  }

  shouldComponentUpdate = (nextProps) => {
    const { filterApplied } = this.props
    if (filterApplied !== nextProps.filterApplied) {
      this.setTableHeight(nextProps.filterApplied)
    }
    return true
  }

  _init = () => {
    const { filterApplied } = this.props
    this.setTableHeight(filterApplied)
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { filterApplied } = this.props
    this.setTableHeight(filterApplied)
  }

  setTableHeight = (filterApplied) => {
    const { innerHeight: height } = window
    let h = height - 285
    if (filterApplied && filterApplied > 0) {
      h -= 65
    }
    this.setState({ tableHeight: h })
  }

  tableChangeHandler = (pagination: TablePaginationConfig, filters, sorts: SorterResult<any> | SorterResult<any>[]) => {
    const { onTableChanged } = this.props
    if (onTableChanged) {
      onTableChanged(pagination, filters, sorts)
    }
  }

  advancedTableHeaderToColumnsType = (
    header: AdvancedTableColumns,
    sorts: AdvancedTableSortValues,
    activeDefaultClassColumn: boolean
  ): ColumnsType<any> => {
    return header
      .filter((col) => !col.hidden)
      .map((col) => this.advancedTableHeaderEntryToColumnType(col, sorts[col.key], activeDefaultClassColumn))
  }

  advancedTableHeaderEntryToColumnType = (
    headerEntry: AdvancedTableColumn,
    sort: AdvancedTableSortDirection,
    activeDefaultClassColumn: boolean
  ): ColumnType<any> => {
    return {
      dataIndex: headerEntry.key.split('.'),
      title:
        headerEntry.title && headerEntry.title !== ''
          ? headerEntry.title
          : __(`fields.labels.${headerEntry.key}`, headerEntry.title),
      render: this.getRenderByType(headerEntry),
      sorter: headerEntry.sortable || headerEntry.sortable === undefined,
      sortOrder: advancedTableSortDirectionToSortOrder(sort),
      sortDirections: ['ascend', 'descend', 'ascend'] as Array<SortOrder>,
      fixed: headerEntry.fixedType,
      className: headerEntry.className || (activeDefaultClassColumn ? 'stw-medium' : ''),
      filterDropdown: headerEntry.filterDropdown,
      filterIcon: headerEntry.filterIcon,
    }
  }

  getRenderByType = (headerEntry: AdvancedTableColumn) => {
    return headerEntry.render || this.getDefaultRender(headerEntry.type)
  }

  getDefaultRender = (kind?: AdvancedTableContentKind) => {
    switch (kind) {
      case AdvancedTableContentKind.DATE:
        return (value) => (value ? <AdvancedTableRowColumn value={value} date format="DD/MM/YYYY" /> : 'n/a')
      case AdvancedTableContentKind.DATE_TIME:
        return (value) => (value ? <AdvancedTableRowColumn value={value} date format="DD/MM/YYYY HH:mm:ss" /> : 'n/a')
      case AdvancedTableContentKind.BOOLEAN:
        return (value) => (value ? __(T.misc.yes) : __(T.misc.no))
      case AdvancedTableContentKind.MULTIPLE_STRING:
        return (value) => (value ? <span>{Array.isArray(value) ? value.join('\n\r') : value}</span> : 'n/a')
      case AdvancedTableContentKind.IDENTIFIERS:
        return (value) => (
          <span>{value.map((identifier) => `${identifier.type}: ${identifier.code}`).join('\n\r')}</span>
        )
      case AdvancedTableContentKind.COUNTER:
        return (value) => (Array.isArray(value) ? value.length : 0)
      case AdvancedTableContentKind.STATUS:
        return (value) => (
          <AdvancedTableRowColumn value={value ? __(`enum.${value}`, value) : 'n/a'} tag options={getTagColor(value)} />
        )
      case AdvancedTableContentKind.ATTRIBUTES:
        // eslint-disable-next-line consistent-return
        return (value) => {
          const keys = Object.keys(value)
          let str = ''
          for (let k = 0; k < keys.length; k++) {
            str += `${str !== '' ? ', ' : ''}${keys[k]}:${value[keys[k]]}`
          }
          return str
        }
      case AdvancedTableContentKind.NUMBER:
        return (value) => <AdvancedTableRowColumn value={value ?? 'n/a'} number />
      case AdvancedTableContentKind.CODE_DESC:
        return (value) => <AdvancedTableRowColumn value={this.getCodeDescription(value)} />
      case AdvancedTableContentKind.STRING:
      case undefined:
        return (value) => <AdvancedTableRowColumn value={value} />
      default:
        return () => <AdvancedTableRowColumn value={`type ${kind} not rendered`} />
    }
  }

  getCodeDescription = (value?: any) => {
    if (value && value.code) {
      return value.description ? `${value.code} - ${value.description}` : value.code
    }
    return 'n/a'
  }

  render() {
    const {
      pagination,
      columns,
      totalElements,
      content,
      border,
      disabledScroll,
      loader,
      activeDefaultClassColumn,
      fixedTableHeight,
      reloadData,
      disabledPagination,
      disabledNoDataFound,
      tableTitle,
    } = this.props
    const { tableHeight } = this.state
    return (
      <div className={border ? 'stw-border' : undefined}>
        {tableTitle && (
          <SectionTitle
            title={!loader && totalElements === 0 ? tableTitle.emptyTitle : tableTitle.title}
            noBorder={!loader && totalElements === 0}
            marginBottom={loader || totalElements > 0 ? 15 : 0}
          />
        )}
        <Row>
          <Col span={24}>
            {!loader && totalElements > 0 && (
              <Table
                columns={this.advancedTableHeaderToColumnsType(
                  columns,
                  pagination.sortValues,
                  activeDefaultClassColumn || false
                )}
                rowKey="id"
                dataSource={content}
                pagination={{
                  hideOnSinglePage: disabledPagination,
                  current: pagination.pageNumber,
                  pageSize: pagination.pageSize,
                  total: totalElements,
                  defaultPageSize: pagination.pageSize,
                  showSizeChanger: totalElements > pagination.pageSize,
                }}
                scroll={disabledScroll ? undefined : { y: fixedTableHeight ? 500 : tableHeight }}
                onChange={this.tableChangeHandler}
              />
            )}
            {!loader && !disabledPagination && totalElements > 0 && (
              <div className="stw-pagination">
                <TableNumberPage
                  totalElements={totalElements}
                  pagination={pagination}
                  disabledPagination={disabledPagination}
                />
                <TableReloadButton reloadData={reloadData} />
              </div>
            )}
            {!loader && totalElements === 0 && !disabledNoDataFound && (
              <NotFound
                title={__(T.misc.noRecordFoundTitle)}
                text={__(T.misc.noRecordFoundText)}
                fullheight={!fixedTableHeight}
              />
            )}
            {loader && <SkeletonTable animate height={tableHeight} />}
          </Col>
        </Row>
      </div>
    )
  }
}
