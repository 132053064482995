import { Component } from 'react'
import { Modal } from 'antd'
import { __ } from '../shared/i18n'
import DraggableCheckboxList from './DraggableCheckboxList'
import { AdvancedTableColumn } from './AdvancedTable'
import AsyncJobs from '../api/AsyncJobs'
import { ModalHeader, ModalAddButton } from '.'

interface Props {
  title: string
  filenamePrefix?: string
  endpoint: string
  columns: AdvancedTableColumn[]
  visible?: boolean
  hide: () => void
}

interface State {
  exportColumns: any[]
}

export class AdvancedTableExportModal extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      exportColumns: props.columns,
    }
  }

  doExport = async () => {
    const { filenamePrefix, endpoint, title } = this.props
    const { exportColumns } = this.state
    await AsyncJobs.insert(
      {
        jobType: 'export',
        description: `${title}_${filenamePrefix || 'export'}_${new Date().getTime()}`,
        jobData: {
          downloadUrl: endpoint,
          exportFileFormat: 'csv',
          exportColumns: exportColumns
            .filter((exportColumn) => exportColumn.hidden === false)
            .map((exportColumn, index) => ({
              seqNumber: index,
              jsonField: exportColumn.dataIndex,
              fileColumnLabel: __(`fields.labels.${exportColumn.key}`, exportColumn.title),
            })),
        },
      },
      undefined,
      this.getInsertJobMessage()
    )
  }

  getInsertJobMessage = () => {
    const href = '/jobs/export'
    return (
      <>
        {__('jobs.requestReceived')} <a href={href}>{__('jobs.link')}</a>
      </>
    )
  }

  handleCancel = () => {
    this.hide()
  }

  handleOk = () => {
    this.doExport().then(this.hide)
  }

  hide = () => {
    this.setState({ exportColumns: [] }, this.props.hide)
  }

  render() {
    const { visible, columns } = this.props
    return (
      <Modal
        centered
        title={<ModalHeader close={this.handleCancel} title={__('messages.export.select_column_to_export')} />}
        onOk={this.handleOk}
        closable={false}
        onCancel={this.handleCancel}
        open={visible}
        footer={null}
        width="40%"
        className="stw-workstation-rfid-antennas-modal"
      >
        <DraggableCheckboxList
          items={columns}
          field="hidden"
          style={{ maxHeight: '50vh' }}
          itemRender={(item: any) => __(`fields.labels.${item.key}`, item.title)}
          onChange={(items) => this.setState({ exportColumns: items })}
        />
        <ModalAddButton pb={5} mTop={35} close={this.handleCancel} confirmType="button" save={this.handleOk} />
      </Modal>
    )
  }
}
