import { api, responseErrorCheck } from './api'
import BaseResource from './BaseResource'

export default class AsyncJobs extends BaseResource {
  static endpoint = '/api/v1/asyncJobs' // /base
  static endpointExport = '/api/v1/exports' // /base

  static async downloadFile(exportId: string) {
    const res = await api.get<any>(`${this.endpointExport}?exportId=${exportId}`)
    return responseErrorCheck<any>(res)
  }
}
