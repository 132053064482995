export default {
  action: {
    close: 'Close',
  },
  error: {
    checklist: {
      noitems: 'No items selected',
      noproducts: 'No product selected',
      alreadyInserted: 'Already in list',
    },
  },
  enum: {
    deviceType: {
      'caen-slate': 'Caen HEX',
      'nordic-id-sampo-native': 'Nordic ID Sampo Native',
      'kit-nlo-labid': 'kit NLO Labid',
      'feig-nfc': 'Feig NFC',
      'impinj-xarray': 'Inpinj XArray',
      'nordic-id-sampo-labid': 'Nordic ID Sampo Labid',
      'impinj-speedway-octane': 'Impinj Speedway Octane',
      'zebra-fx-series': 'Zebra FX Series',
      'caen-quattro': 'Caen Quattro',
      'rfid-emulator': 'Emulatore Rfid',
    },
    type: {
      UHF_TAG: 'UHF Tag',
      NFC_TAG: 'NFC Tag',
      SIMPLE_ITEM_IDENTIFIER: 'Simple Item Identifier',
    },
    itemStatus: {
      ASSOCIATED: 'Associated',
      NOT_ASSOCIATED: 'Not associated',
      REPLACED_ASSOCIATION: 'Replaced',
    },
    operationType: {
      ENCODING: 'ENCODING',
      TRANSFER_TO_ZONE: 'TRANSFER_TO_ZONE',
      OUTBOUND: 'OUTBOUND',
      INBOUND: 'INBOUND',
      INVENTORY: 'INVENTORY',
      INVENTORY_PARCEL: 'INVENTORY_PARCEL',
      TAG_INFO: 'TAG_INFO',
      SIMPLE_ITEM_LIST: 'SIMPLE_ITEM_LIST',
      SIMPLE_PARCEL_LIST: 'SIMPLE_PARCEL_LIST',
      CYCLE_COUNT: 'CYCLE_COUNT',
      PRINT: 'PRINT',
      PACKING: 'PACKING',
      PICKING: 'PICKING',
      SORTING: 'SORTING',
      PHASETO_PHASE: 'PHASETO_PHASE',
    },
    status: {
      OPEN: 'Open',
      CLOSED: 'Closed',
      OUTBOUND_ERROR: 'Outbound error',
      INBOUND_ERROR: 'Inbound error',
      RECEIVE_AWAIT: 'Receive await',
      READY_TO_RECEIVE: 'Ready to receive',
      IN_INBOUND: 'In inbound',
      IN_TRANSIT: 'In transit',
      SEND_AWAIT: 'Send await',
      READY_TO_SEND: 'Ready to send',
      IN_OUTBOUND: 'In outbound',
      RECEIVED: 'Received',
      PACKED: 'Packed',
      DRAFT: 'Draft',
      DETECTED: 'Detected',
      EXPECTED: 'Expected',
      UNEXPECTED: 'Unexpected',
      MISSING: 'Missing',
      ASSOCIATED: 'Associated',
      NOT_ASSOCIATED: 'Not Associated',
      REPLACED_ASSOCIATION: 'Replaced',
      READ: 'Read',
      TO_READ: 'To read',
      CREATED: 'Created',
      POPULATING: 'Populating',
      POPULATE_ERROR: 'Populating error',
      CLOSING: 'Closing',
      CLOSE_ERROR: 'Close error',
      MOVE_AND_CLOSE: 'Move and close',
    },
    checklistType: {
      CHECKLIST_BY_ITEM: 'CHECKLIST_BY_ITEM',
      CHECKLIST_BY_PRODUCT: 'CHECKLIST_BY_PRODUCT',
    },
    OUTBOUND: 'Outbound',
    INBOUND: 'Inbound',
    PACKING: 'Packing',
    DRAFT: 'Draft',
    PACKED: 'Packed',
    IN_OUTBOUND: 'In Outbound',
    READY_TO_SEND: 'Ready to send',
    SEND_AWAIT: 'Send await',
    IN_TRANSIT: 'In Transit',
    IN_INBOUND: 'In Inbound',
    READY_TO_RECEIVE: 'Ready to receive',
    RECEIVE_AWAIT: 'Receive await',
    OUTBOUND_ERROR: 'Outbound error',
    INBOUND_ERROR: 'Inbound error',
    RECEIVED: 'Received',
    ASSOCIATED: 'Associated',
    NOT_ASSOCIATED: 'Not Associated',
    REPLACED_ASSOCIATION: 'Replaced',
    PICK_IN_PROGRESS: 'Pick in progress',
    OPEN: 'Open',
    CLOSED: 'Closed',
    NO: 'NO',
    PENDING: 'Pending',
    PARTIAL: 'Partial',
    YES: 'YES',
    CREATED: 'Created',
    POPULATING: 'Populating',
    POPULATE_ERROR: 'Populating error',
    CLOSING: 'Closing',
    CLOSE_ERROR: 'Close error',
    MOVE_AND_CLOSE: 'Move and close',
  },
  color: {
    DRAFT: 'yellow',
    PACKED: 'green',
    IN_OUTBOUND: 'yellow',
    READY_TO_SEND: 'yellow',
    SEND_AWAIT: 'yellow',
    IN_TRANSIT: 'green',
    IN_INBOUND: 'yellow',
    READY_TO_RECEIVE: 'yellow',
    RECEIVE_AWAIT: 'yellow',
    OUTBOUND_ERROR: 'red',
    INBOUND_ERROR: 'red',
    RECEIVED: 'yellow',
    ASSOCIATED: 'green',
    NOT_ASSOCIATED: 'yellow',
    REPLACED_ASSOCIATION: 'yellow',
  },
  titles: {
    items: 'Items',
    item: 'item',
    products: 'Products',
    product: 'Product',
    zones: 'Zones',
    zone: 'zone',
    places: 'Places',
    place: 'place',
    operations: 'Operations',
    operation: 'operation',
    itemconfiguration: 'Item configuration',
    users: 'Users',
    capabilitiesGroup: 'Capabilities group',
    capabilities: 'Capabilities',
    roles: 'Roles',
    roleMap: 'Role map',
    parcels: 'Parcels',
    parcel: 'Parcel',
    asn: 'Shipment',
    modalChecklistQuantity: 'Edit Quantity',
    sortingGroupEntry: 'Sorting Group Entry',
    applications: 'Applications',
    parcelContent: 'Parcel Content',
    itemType: 'Item type',
    modalchecklistQuantity: 'Edit quantity for product',
  },
  fields: {
    labels: {
      montante: 'Section',
      ripiano: 'Bin',
      scaffale: 'Bay',
      stato: 'Status',
      codiceShelf: 'Stock Compartment',
      //report product
      attributesBarcode: 'Barcode BC',
      //report item
      productAttributesBarcode: 'Barcode BC',
      //product edit & detail
      barcode: 'Barcode BC',
      detectingUser: 'Detecting user',
      detectingDate: 'Detecting date',
      closeUser: 'Close User',
      createAsn: 'Asn',
      createParcel: 'Parcel',
      empty: '',
      color: 'Color',
      size: 'Size',
      material: 'Material',
      style: 'Style',
      identifiers: 'Identifiers',
      items: 'Items',
      ignoredItems: 'Ignored items',
      item: 'Item',
      product: 'Product',
      code: 'Code',
      description: 'Description',
      shortDescription: 'Short description',
      place: 'Place',
      address: 'Address',
      longitude: 'Longitude',
      latitude: 'Latitude',
      enabled: 'Enabled',
      operationId: 'Operation ID',
      type: 'Type',
      clients: 'Clients',
      options: 'Options',
      creationDate: 'Created at',
      lastModifyDate: 'Updated at',
      action: '',
      itemType: 'Item type',
      details: 'N. detail',
      eventDetails: 'Details',
      productDescription: 'Product description',
      productCode: 'Product code',
      productStyleValue: 'Product Model',
      productMaterialValue: 'Product material',
      productSizeValue: 'Product size',
      productColorValue: 'Product color',
      productSeason: 'Product season',
      zone: 'Zone',
      zones: 'Zones',
      ignoreZones: 'Ignore zones',
      checkZone: 'Check zone',
      lostZone: 'Lost zone',
      zonePlace: 'Place',
      zoneCode: 'Zone code',
      placeCode: 'Place code',
      id: 'Identifier',
      productCodes: 'Product Code',
      defaultZone: 'Zona default',
      externalCode: 'Ext. code',
      countryCode: 'Country code',
      labels: 'Labels',
      autostart: 'Autostart',
      identifierCode: 'Identifiers code',
      originPlace: 'Origin place',
      destinationPlace: 'Destination place',
      shippingDate: 'Shipping date',
      autoCreated: 'Autocreated',
      receivingDate: 'Receiving date',
      operation: 'Operation',
      ddtNumber: 'DDT number',
      ddtDate: 'DDT date',
      lastModifyUser: 'Last modify user',
      creationUser: 'Creation user',
      intransitZone: 'Intransit zone',
      clientPlace: 'Client place',
      operationType: 'Operation type',
      checklistType: 'Checklist type',
      confirmationUser: 'Confirm user',
      confirmationDate: 'Confirm date',
      status: 'Status',
      asn: 'Shipping',
      palletCode: 'Pallet code',
      asnDestinationPlace: 'Destination',
      asnOriginPlace: 'Origin',
      errorMessage: 'Error message',
      carrierCode: 'Carrier code',
      parcelEntryQuantity: 'Quantity',
      weight: 'Weight',
      checklist: 'Checklist',
      capabilities: 'Capabilities',
      operations: 'Operations',
      quantity: 'Quantity',
      season: 'Season',
      products: 'Products',
      ignoredProducts: 'Ignored products',
      detected: 'Detected',
      unexpected: 'Unexpected',
      destinationZone: 'Destination Zone',
      roles: 'Roles',
      username: 'Username',
      email: 'Email',
      name: 'Name',
      surname: 'Surname',
      sector2Zone: 'Sector 2 Zone',
      sector3Zone: 'Sector 3 Zone',
      user: 'User',
      autoStart: 'Autostart',
      lastSeen: 'Last seen',
      locked: 'Locked',
      userPlaces: 'User places',
      deviceController: 'Controller',
      targetZone: 'Target zone',
      rfidAntennasNumber: 'N° antennas',
      antennasCount: 'N° antennas',
      ipAddress: 'IP Address',
      txPower: 'Tx Power',
      mode: 'Mode',
      tagAgeInterval: 'Tag Age Interval',
      connectionMode: 'Connection mode',
      maxConnectionTimeout: 'Max connection timeout (milliseconds)',
      keepAliveEnabled: 'Keep alive enabled',
      keepAlivePeriod: 'Keep alive period',
      linkMonitorModeEnabled: 'Link Monitor Mode Enabled',
      linkDownThreshold: 'Link Down Threshold',
      transferToZoneEnabled: 'Transfer To Zone Enabled',
      rfidReader: 'RFID reader',
      rfidAntenna: 'RFID antenna',
      lifespan: 'Lifespan',
      reader: 'Reader',
      readerPort: 'Reader port',
      rxSensitivity: 'Rx Sensitivity',
      deviceType: 'Device type',
      pulseDuration: 'Pulse duration',
      gpoMode: 'GPO mode',
      portNumber: 'Port number',
      port: 'Port',
      identifierType: 'Identifier type',
      role: 'Role',
      balance: 'Scale',
      protocol: 'Protocol',
      dataSocketPort: 'Data Socket Port',
      deviceManagerUrl: 'Device Manager URL',
      deviceManagerWebsocket: 'WebSocket Device Manager URL',
      readerMode: 'Reader mode',
      searchMode: 'Search mode',
      session: 'Session',
      triggerEnabled: 'Trigger enabled',
      timeoutEnabled: 'Timeout enabled',
      startType: 'Start type',
      gpioStartPort: 'Gpio start port',
      stopType: 'Stop type',
      debounceTime: 'Debounce time',
      gpioStopPort: 'Gpio stop port',
      timeout: 'Timeout',
      triggerTimeoutSelect: 'Working mode',
      rfidAntennas: 'Antennas',
      enumerationType: 'Enumeration Type',
      formSchema: 'FormSchema',
      settingsSchema: 'Settings',
      externalId: 'External ID',
      hostname: 'Hostname',
      createDefaultZones: 'Create Default Zones',
      featureType: 'Feature type',
      eventDate: 'Event date',
      operationInstanceId: 'Operation instance id',
      productSize: 'Size',
      dropValue: 'Drop',
      rfidEnabled: 'Rfid enabled',
      styleValue: 'Model value',
      styleDescription: 'Model description',
      materialValue: 'Material value',
      materialDescription: 'Material description',
      colorValue: 'Color value',
      colorDescription: 'Color description',
      sizeValue: 'Size value',
      sizeDescription: 'Size description',
      drop: 'Drop',
      read: 'Read',
      expected: 'Expected',
      missing: 'Missing',
      parcelCode: 'Parcel code',
      parcelDescription: 'Parcel description',
      operationDate: 'Operation date',
      operationDescription: 'Operation description',
      homepage: 'Homepage',
      priority: 'Priority',
      checklistPlaceId: 'CheckList Place',
      destinationPlaceId: 'Destination Place',
      itemConfigurations: 'Item configuration',
      itemTypes: 'Item type',
      pickingOperation: 'Picking operation',
      itemConfiguration: 'Configuration',
      filters: 'Filters',
      parcels: 'Parcels',
      fieldOfView: 'Field on view',
      operationLabel: 'Operation',
      operationPlace: 'Operation Place',
      productMaterial: 'Product Material',
      productStyle: 'Product Model',
      productColor: 'Product Color',
      settingsIpAddress: 'IP Address',
      settingsConnectionMode: 'Connection Mode',
      settingsDebounceTime: 'Debounce Time',
      settingsEmulationMode: 'Emulation Mode',
      settingsGpioStartPort: 'Gpio Start Port',
      settingsEpcs: 'EPCS',
      settingsGpioStopPort: 'Gpio Stop Port',
      settingsKeepAliveEnabled: 'Keep Alive Enabled',
      settingsKeepAlivePeriod: 'Keep Alive Period',
      settingsLinkMonitorModeEnabled: 'Link Monitor Mode Enabled',
      settingsMaxConnectionTimeout: 'Max Connection Timeout',
      settingsReaderMode: 'Reader Mode',
      settingsSearchMode: 'Search Mode',
      settingsSession: 'Session',
      settingsStartType: 'Start Type',
      settingsStopType: 'Stop Type',
      settingsTimeout: 'Timeout',
      settingsTimeoutEnabled: 'Timeout Enabled',
      settingsTriggerEnabled: ' Trigger Enabled',
      attributesStato: 'Status',
      attributesRicondizionamentoFornitore: 'Ricondizionamento Fornitore',
      attributesCodiceShelf: 'Stock Compartment',
      attributesMontante: 'Section',
      attributesRipiano: 'Bin',
      attributesScaffale: 'Bay',
      attributesBarcodebc: 'Barcode BC',
      attributesBarcodebctrim: 'Barcode BC trim',
      attributesBarcodejde: 'Barcode JDE',
      attributesBarcoderoq: 'Barcode ROQ',
      attributesBarcoderoqtrim: 'Barcode ROQ trim',
      attributesClassiddao: 'Class',
      attributesDepartmentiddao: 'Department',
      attributesDimensionsystem: 'Dimension System',
      attributesEan: 'EAN',
      attributesEanidpadre: 'Father EAN',
      attributesItemid: 'Item Id',
      attributesModellocodice: 'Model',
      attributesStagioneroq: 'ROQ Season',
      attributesSubclassiddao: 'Subclass',
      attributesSubdepartmentiddao: 'Subdepartment',
      attributesTipologiacapo: 'Typology',
      attributesTranslationcolor: 'Color',
      attributesTranslationdescription: 'Description',
      attributesRfidEnabled: 'RFID enabled',
      inventoryId: 'Inventory',
      itemCreationDate: 'Created at',
      itemLastModifyDate: 'Updated at',
      itemZone: 'Item zone',
      itemZonePlace: 'Place',
      itemProductDescription: 'Product description',
      itemProductCode: 'Product code',
      itemProductSeason: 'Product season',
      itemAttributesStato: 'Status',
      itemAttributesRicondizionamentoFornitore: 'Ricondizionamento Fornitore',
      itemAttributesCodiceShelf: 'Stock Compartment',
      itemAttributesMontante: 'Section',
      itemAttributesRipiano: 'Bin',
      itemAttributesScaffale: 'Bay',
      itemAttributesBarcodebc: 'Barcode BC',
      itemAttributesBarcodebctrim: 'Barcode BC trim',
      itemAttributesBarcodejde: 'Barcode JDE',
      itemAttributesBarcoderoq: 'Barcode ROQ',
      itemAttributesBarcoderoqtrim: 'Barcode ROQ trim',
      itemAttributesClassiddao: 'Class',
      itemAttributesDepartmentiddao: 'Department',
      itemAttributesDimensionsystem: 'Dimension System',
      itemAttributesEan: 'EAN',
      itemAttributesEanidpadre: 'Father EAN',
      itemAttributesItemid: 'Item Id',
      itemAttributesModellocodice: 'Model',
      itemAttributesStagioneroq: 'ROQ Season',
      itemAttributesSubclassiddao: 'Subclass',
      itemAttributesSubdepartmentiddao: 'Subdepartment',
      itemAttributesTipologiacapo: 'Typology',
      itemAttributesTranslationcolor: 'Color',
      itemAttributesTranslationdescription: 'Description',
      itemAttributesRfidEnabled: 'RFID enabled',
      productAttributesBarcodebc: 'Barcode BC',
      productAttributesBarcodebctrim: 'Barcode BC trim',
      productAttributesBarcodejde: 'Barcode JDE',
      productAttributesBarcoderoq: 'Barcode ROQ',
      productAttributesBarcoderoqtrim: 'Barcode ROQ trim',
      productAttributesClassiddao: 'Class',
      productAttributesDepartmentiddao: 'Department',
      productAttributesDimensionsystem: 'Dimension System',
      productAttributesEan: 'EAN',
      productAttributesEanidpadre: 'Father EAN',
      productAttributesItemid: 'Item Id',
      productAttributesModellocodice: 'Model',
      productAttributesStagioneroq: 'ROQ Season',
      productAttributesSubclassiddao: 'Subclass',
      productAttributesSubdepartmentiddao: 'Subdepartment',
      productAttributesTipologiacapo: 'Typology',
      productAttributesTranslationcolor: 'Color',
      productAttributesTranslationdescription: 'Description',
      productAttributesRfidEnabled: 'RFID enabled',
      keepAliveSocketPort: 'Keep alive socket port',
      capabilityGroups: 'Capability Groups',
      modalKey: 'Custom key',
      modalCode: 'Code',
      group: 'Group',
      tid: 'TID',
      ndef: 'NDEF',
      settings: {
        ipAddress: 'IP address',
        portNumber: 'Port number',
        port: 'Port',
      },
      sortingGroupEntries: 'Sorting Group Entries',
      sortingGroupReads: 'Sorting Group Reads',
      attribute: 'Attribute',
      defaultValue: 'Default value',
      flow: 'Flow',
      originZone: 'Origin zone',
      picking: 'Picking',
      associatedPicking: 'Associated Picking',
      controller: 'Controller',
      asnCode: 'Shipment Code',
      closingUser: 'Closing User',
      closingDate: 'Closing Date',
      pickingEntries: 'Picking Entries',
      noPickingEntries: 'No picking entries found',
      pickingReads: 'Picking Reads',
      discriminator: 'Discriminator',
      discriminatorKey: 'Discriminator',
      sorting: 'Sorting',
      publicAccessible: 'Publicly Accessible',
      value: 'Value',
      emulationMode: 'Emulation mode',
      epcs: 'EPCS list',
      minEpcDelay: 'Min EPC delay',
      maxEpcDelay: 'Max EPC delay',
      memoryBank: 'Memory Bank',
      socketPort: 'Socket Port',
      gpioPolling: 'Gpio Polling Time',
      loginUsername: 'Login Username',
      loginPassword: 'Login Password',
      optional: 'Optional',
      regex: 'Regex',
      item_type: 'Item Type',
      deleteDate: 'Delete Date',
      configurationDetail: 'Configuration Detail',
      productionOrderRow: 'Production Order Row',
      productionOrder: 'Production Order',
      zoneCodes: 'Zone codes',
      zoneDescriptions: 'Zone descriptions',
      placeCodes: 'Place codes',
      placeDescriptions: 'Place descriptions',
      zonePlaceCode: 'Place code',
      zonePlaceDescription: 'Place description',
      originPlaceCodes: 'Origin place codes',
      originPlaceDescriptions: 'Origin place descriptions',
      originZoneCodes: 'Origin zone codes',
      originZoneDescriptions: 'Origin zone descriptions',
      destinationPlaceCodes: 'Destination place codes',
      destinationPlaceDescriptions: 'Destination place descriptions',
      destinationZoneCodes: 'Destination zone codes',
      destinationZoneDescriptions: 'Destination zone descriptions',
      clientPlaceCodes: 'Client place codes',
      clientPlaceDescriptions: 'Client place descriptions',
      intransitZoneCodes: 'Intrasit zone codes',
      intransitZoneDescriptions: 'Intrasit zone descriptions',
      operationCodes: 'Operation codes',
      operationDescriptions: 'Operation descriptions',
      operationPlaceCodes: 'Operation place codes',
      operationPlaceDescriptions: 'Operation place descriptions',
      parcelZoneCodes: 'Parcel zone codes',
      parcelZoneDescriptions: 'Parcel zone descriptions',
      flowCodes: 'Flow codes',
      flowDescriptions: 'Flow descriptions',
      asnCodes: 'Shipment codes',
      asnDescriptions: 'Shipment descriptions',
      sector2ZoneCodes: 'Sector2 zone codes',
      sector2ZoneDescriptions: 'Sector2 zone descriptions',
      sector3ZoneCodes: 'Sector3 zone codes',
      sector3ZoneDescriptions: 'Sector3 zone descriptions',
      controllerCodes: 'Controller codes',
      controllerDescriptions: 'Controller descriptions',
      identifiersSIMPLE_ITEM_IDENTIFIER: 'S.I.L.',
      itemZoneCodes: 'Item Zone Codes',
      itemZoneDescriptions: 'Item Zone Descriptions',
      itemPlaceCodes: 'Item Place Codes',
      itemPlaceDescriptions: 'Item Place Descriptions',
      itemAttributesItemProductCode: 'Item Product Code',
      itemProductAttributesBarcodeROQTrim: 'Item Product Barcode ROQTrim',
      itemProductAttributesBarcodeROQ: 'Item Product Barcode ROQ',
      itemProductAttributesImageId: 'Item Product ImageId',
      itemProductAttributesCustomAttribute: 'Item Product Custom',
      itemProductAttributesMeasure: 'Item Product Measure',
      itemProductAttributesSubdepartment: 'Item Product Subdepartment',
      itemProductAttributesVariantType: 'Item Product Variant Type',
      itemProductAttributesDimensionSystem: 'Item Product Dimensions System',
      itemProductAttributesPictureId: 'Item Product PictureId',
      itemProductAttributesClass: 'Item Product Class',
      itemProductAttributesLabel: 'Item Product Label',
      itemProductAttributesEan: 'Item Product Ean',
      itemProductAttributesBarcodeJDE: 'Item Product BarcodeJDE',
      itemProductAttributesSubclass: 'Item Product SubClass',
      itemProductAttributesVariantCode: 'Item Product Variant Code',
      itemProductAttributesDepartment: 'Item Product Department',
      itemProductAttributesItemid: 'Item Product ItemId',
      itemProductAttributesBarcode: 'Item Product Barcode',
      itemProductAttributesCategory: 'Item Product Category',
      itemProductAttributesEanIdPadre: 'Item Product Ean Parent',
      itemProductAttributesCustom12: 'Item Product Custom 12',
      itemProductAttributesStagioneROQ: 'Item Product Season ROQ',
      itemProductAttributesBarcodeBCTrim: 'Item Product Barcode BCTrim',
      itemProductAttributesLot: 'Item Product Lot',
      itemProductAttributesExternalUrl: 'Item Product ExternalURL',
      itemProductAttributesBrand: 'Item Product Brand',
      itemProductRfidEnabled: 'Item Rfid Enabled',
      itemProductStyleValue: 'Item Product Model',
      itemProductMaterialValue: 'Item Product Material',
      itemProductSizeValue: 'Item Size Material',
      itemProductColorValue: 'Item Color Material',
      itemIdentifierCode: 'Item Identifier Code',
      expectedZone: 'Expected Zone',
      attributes: {
        title: 'Attributes',
        list: 'List',
        listPosition: 'List position',
        shift: 'Shift',
        destination: 'Destination',
        identifier: 'Identifier',
        regTime: 'Registration Time',
        customerName: 'Customer Name',
        product: 'Product',
        customer: 'Customer',
        event: 'Event',
        model: 'Model',
        regDate: 'Registration Date',
      },
      displayPriority: 'Display priotity',
      templateType: 'Type',
      outputType: 'Output Type',
      optLock: 'Operation lock',
      templateBody: 'Body content',
    },
    placeholders: {
      templateType: 'Select Type',
      outputType: 'Select Output Type',
      optLock: 'Choose operation lock',
      templateBody: 'Digit body content',
      quantity: 'Insert a quantity',
      closeUser: 'Select close user',
      destinationPlaceId: 'Destination Place Id',
      operationType: 'Operation Type',
      operationId: 'Operation Id',
      code: 'Insert Code',
      chooseCode: 'Select code',
      checklistPlaceId: 'Check List Place Id',
      description: 'Insert Description',
      shortDescription: 'Insert Short Description',
      place: 'Search Place',
      zone: 'Search Zone',
      address: 'Insert Address',
      longitude: 'Insert Longitude',
      latitude: 'Insert Latitude',
      type: 'Choose Type',
      clients: 'Insert Clients',
      deviceController: 'Insert controller',
      rfidReader: 'Insert reader',
      operation: 'Insert operation',
      targetZone: 'Insert target zone',
      rfidAntenna: 'Insert antenna',
      deviceType: 'Insert device type',
      externalCode: 'Insert external code',
      countryCode: 'Insert country code',
      labels: 'Insert labels',
      readerMode: 'Reader mode',
      searchMode: 'Search mode',
      session: 'Select session',
      triggerEnabled: 'Trigger enabled',
      timeoutEnabled: 'Timeout enabled',
      startType: 'Start type',
      gpioStartPort: 'Gpio start port',
      stopType: 'Stop type',
      debounceTime: 'Debounce time',
      gpioStopPort: 'Gpio stop port',
      timeout: 'Timeout',
      ipAddress: 'IP Address',
      connectionMode: 'Connection mode',
      maxConnectionTimeout: 'Max Connection Timeout',
      keepAliveEnabled: 'Keep Alive Enabled',
      keepAlivePeriod: 'Keep Alive Period',
      linkMonitorModeEnabled: 'Link Monitor Mode Enabled',
      linkDownThreshold: 'Link Down Threshold',
      transferToZoneEnabled: 'Transfer To Zone Enabled',
      triggerTimeoutSelect: 'Selected working mode',
      portNumber: 'Insert port number',
      port: 'Insert port number',
      gpoMode: 'Select GPO mode',
      pulseDuration: 'Insert pulse duration',
      enumerationTypes: 'Search Enumeration Types',
      itemType: 'Search item type',
      username: 'Insert username',
      email: 'Insert email address',
      name: 'Insert name',
      surname: 'Insert surname',
      deviceManagerWebsocket: {
        label: 'WebSocket Device Manager URL',
        protocol: 'Select protocol',
        hostname: 'Insert hostname',
        port: 'Insert port',
      },
      deviceManagerUrl: {
        label: 'Device Manager URL',
        protocol: 'Select protocol',
        hostname: 'Insert hostname',
        port: 'Insert port',
      },
      featureType: 'Select feature type',
      errorMessage: 'Insert error message',
      productDescription: 'Insert product description',
      styleValue: 'Insert style value',
      styleDescription: 'Insert style description',
      materialValue: 'Insert material value',
      materialDescription: 'Insert material description',
      colorValue: 'Insert color value',
      colorDescription: 'Insert color description',
      sizeValue: 'Insert size value',
      sizeDescription: 'Insert size description',
      drop: 'Insert drop',
      homepage: 'Select homepage',
      priority: 'Insert priority',
      capabilities: 'Select capabilities',
      fieldOfView: 'Select field on view',
      mode: 'Select mode',
      user: 'Select user',
      sector2Zone: 'Select sector 2 zone',
      sector3Zone: 'Select sector 3 zone',
      lifespan: 'Insert lifespan',
      enumerationTypeIds: 'Select enumeration type',
      rfidEnabled: 'Select option',
      selectOption: 'Select option',
      style: 'Insert style',
      material: 'Insert material',
      productSize: 'Insert product size',
      color: 'Insert color',
      season: 'Insert season',
      dropValue: 'Insert drop value',
      destinationPlace: 'Insert destination place',
      creationUser: 'Select user',
      lastModifyUser: 'Select user',
      ddtNumber: 'Insert ddt number',
      intransitZone: 'Insert intransit zone',
      clientPlace: 'Select client place',
      autoCreated: 'Select option',
      asn: 'Insert asn',
      status: 'Select status',
      asnOriginPlace: 'Insert original place',
      asnDestinationPlace: 'Insert destination place',
      palletCode: 'Insert pallet code',
      carrierCode: 'Insert carrier code',
      checklistType: 'Select type',
      confirmationUser: 'Select user',
      operationPlace: 'Select operation place',
      productStyle: 'Insert product model',
      productMaterial: 'Insert product material',
      productColor: 'Insert product color',
      productSeason: 'Insert product season',
      identifierCode: 'Insert identifiers code',
      zonePlace: 'Select zone place',
      productCode: 'Insert product code',
      deviceControllerId: 'Select device controller',
      targetZoneIds: 'Select zones',
      userId: 'Select user',
      operationIds: 'Select operations',
      equalCodes: 'Insert codes',
      equalUsernames: 'Insert username',
      readerPort: 'Insert reader port',
      dataSocketPort: 'Insert data socket port',
      keepAliveSocketPort: 'Insert keep alive socket port',
      roles: 'Select roles',
      roleIds: 'Select roles',
      operations: 'Select operations',
      likeCountryCodes: 'Insert country code',
      productAttributesBarcodebc: 'Insert Barcode BC',
      products: 'Select product',
      modalKey: 'Insert key',
      actionCode: 'Insert code',
      customKey: 'Insert custom key',
      customValue: 'Insert custom value',
      identifierType: 'Select Identifier Type',
      role: 'Insert role',
      value: 'Insert value',
      key: 'Insert Key',
      settings: {
        ipAddress: 'Insert ip address',
        port: 'Insert port number',
      },
      tagAgeInterval: 'Insert tag age interval',
      defaultValue: 'Insert default value',
      closingUser: {
        id: 'Insert closing user',
      },
      discriminator: 'Insert discriminator',
      discriminatorKey: 'Insert discriminator',
      sorting: 'Insert sorting',
      emulationMode: 'Insert emulation mode',
      epcs: 'Insert EPCS list',
      minEpcDelay: 'Insert min EPC delay',
      maxEpcDelay: 'Insert max EPC delay',
      memoryBank: 'Insert Memory Bank',
      socketPort: 'Insert Socket Port',
      gpioPolling: 'Insert Gpio Polling Time',
      loginUsername: 'Insert login Username',
      loginPassword: 'Insert login Password',
      regexFilter: 'Insert Regex Filter',
      optional: '',
      initial_type: 'Initial type',
      item_type: 'Item type',
      itemTypes: 'Select item type',
      pickingOperation: 'Select picking operation',
      attributes: {
        list: 'Select option',
        listPosition: 'Select option',
        shift: 'Select option',
        destination: 'Select option',
        identifier: 'Select option',
        regTime: 'Select option',
        customerName: 'Select option',
        product: 'Select option',
        customer: 'Select option',
        event: 'Select option',
        model: 'Select option',
        regDate: 'Select option',
      },
      itemPlaceCodes: 'Insert Item Place Codes',
      itemPlaceDescriptions: 'Insert Item Place Descriptions',
      itemAttributesItemProductCode: 'Insert Item Product Code',
      itemProductAttributesBarcodeROQTrim: 'Select Item Product Barcode ROQTrim',
      itemProductAttributesBarcodeROQ: 'Select Item Product Barcode ROQ',
      itemProductAttributesImageId: 'Select Item Product ImageId',
      itemProductAttributesCustomAttribute: 'Select Item Product Custom',
      itemProductAttributesMeasure: 'Select Item Product Measure',
      itemProductAttributesSubdepartment: 'Select Item Product Subdepartment',
      itemProductAttributesVariantType: 'Select Item Product Variant Type',
      itemProductAttributesDimensionSystem: 'Select Item Product Dimensions System',
      itemProductAttributesPictureId: 'Select Item Product PictureId',
      itemProductAttributesClass: 'Select Item Product Class',
      itemProductAttributesLabel: 'Select Item Product Label',
      itemProductAttributesEan: 'Select Item Product Ean',
      itemProductAttributesBarcodeJDE: 'Select Item Product BarcodeJDE',
      itemProductAttributesSubclass: 'Select Item Product SubClass',
      itemProductAttributesVariantCode: 'Select Item Product Variant Code',
      itemProductAttributesDepartment: 'Select Item Product Department',
      itemProductAttributesItemid: 'Select Item Product ItemId',
      itemProductAttributesBarcode: 'Select Item Product Barcode',
      itemProductAttributesCategory: 'Select Item Product Category',
      itemProductAttributesEanIdPadre: 'Select Item Product Ean Parent',
      itemProductAttributesCustom12: 'Select Item Product Custom 12',
      itemProductAttributesStagioneROQ: 'Select Item Product Season ROQ',
      itemProductAttributesBarcodeBCTrim: 'Select Item Product Barcode BCTrim',
      itemProductAttributesLot: 'Select Item Product Lot',
      itemProductAttributesExternalUrl: 'Select Item Product ExternalURL',
      itemProductAttributesBrand: 'Select Item Product Brand',
      itemProductRfidEnabled: 'Select Item Rfid Enabled',
      itemProductStyleValue: 'Select Item Product Model',
      itemProductMaterialValue: 'Select Item Product Material',
      itemProductSizeValue: 'Select Item Size Material',
      itemProductColorValue: 'Select Item Color Material',
      itemIdentifierCode: 'Select Item Identifier Code',
      expectedZone: 'Expected Zone',
      displayPriority: 'Digit priotity',
      externalId: 'Insert external id',
    },
  },
  misc: {
    actions: 'Actions',
    operationActionSuccess: 'Operation requested with success',
    operationActionError: 'Operation requested failed',
    stationLink: 'Open RFID Station',
    quantity: 'Insert Quantity',
    itemIds_title: 'Item Id',
    itemId_subtitle: '',
    itemId_title: 'Item Id',
    items_title: 'Items',
    description: 'Description',
    total: 'Total',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    user: 'User',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    create: 'Create',
    create_new: 'Create new',
    select: 'Select',
    update: 'Update',
    edit: 'Edit',
    not_found: 'Not found',
    yes: 'yes',
    no: 'no',
    add_new: 'Add new',
    attributes_title: 'Attributes',
    attributes_subtitle: '',
    attributeKeys_title: 'Attributes',
    attributeKeys_subtitle: '',
    settings_title: 'Settings',
    settings_subtitle: '',
    details_title: 'Identifier list',
    details_subtitle: '',
    options_title: 'Options',
    options_subtitle: '',
    delete_record: 'Are you sure that you want to delete this',
    delete_noundo: 'There is no undo',
    enabled: 'Enabled',
    disabled: 'Disabled',
    apply: 'Apply',
    manage_columns: 'Manage columns',
    items: 'Items',
    products: 'Products',
    capabilities_title: 'Capabilities',
    operations_title: 'Operations',
    roles_title: 'Roles',
    ports_title: 'Ports',
    identifiers_title: 'Item identifiers',
    userPlaces_title: 'User places',
    antennas: 'Antennas',
    antennas_title: 'Antennas',
    addAttribute: 'Add attribute',
    addIdentifier: 'Add identifier',
    addNewValue: 'Add new value',
    addAntenna: 'Add Antenna',
    baseOptions_title: 'Base options',
    advancedOptions_title: 'Advanced options',
    port: 'Port',
    addFilters: 'Add Filters',
    from: 'from',
    to: 'to',
    of: 'of',
    resetAll: 'Reset all',
    reset: 'Reset',
    add_antennas: 'Add Antennas',
    add_antenna: 'Add Antenna',
    associate_antennas: 'Associate Antennas',
    set_scale: 'Set scale',
    change_scale: 'Change scale',
    associate_scales: 'Associate Scales',
    itemEvents_title: 'Item events',
    productAttributes_title: 'Product attributes',
    products_title: 'Products',
    duplicatePlace: 'you cannot select the same place twice',
    duplicateAttribute: 'you cannot select the same attribute twice',
    validationError: 'Validation error',
    loadingDate: 'wait for loading',
    logout: 'Logout',
    unknown: 'unknow',
    userInfo: 'User info',
    applicationInfo: 'Application info',
    noRecordFoundTitle: 'No data found',
    noRecordFoundText: 'Try again or change your search filters',
    copyToClipboard: 'Text copied to clipboard',
    duplicatePortNumber: 'you cannot add the same port number twice',
    duplicateEntries: 'Duplicate entries',
    customKey: 'Custom key',
    insertKey: 'Insert key',
    customValue: 'Custom value',
    insertValue: 'Insert value',
    insertDescription: 'Insert description',
    reload: 'Reload',
    clear: 'Clear',
    remove: 'Remove',
    customKeyRequired: 'Custom key is required',
    customKeyDuplicate: 'Duplicate Custom key',
    actionCodeDuplicate: 'Duplicate Action Code',
    codeRequired: 'Code is required',
    codeDuplicate: 'Duplicate Code',
    customValueRequired: 'Custom value is required',
    descriptionRequired: 'Description is required',
    alreadyExist: 'already exist',
    systemOptionKey: 'is a system key and cannot be added',
    operationLogDisabledTitle: "I'm sorry. No records found",
    operationLogDisabledText: 'This operation is not enabled',
    optionKey: 'Option key',
    optionValue: 'Option value',
    addNewOption: 'Add new option',
    genericErrorRequired: 'Field is required',
    genericErrorDuplicate: 'Duplicate value',
    identifierType: 'Identifier Type',
    role: 'Role',
    identifierTypeRequired: 'Identifier Type is required',
    identifierTypeDuplicate: 'Duplicate identifier Type',
    roleRequired: 'Role is required',
    value: 'Value',
    createValue: 'Create Value',
    valueNotFound: 'No value found',
    valueRequired: 'Value is required',
    key: 'Key',
    keyRequired: 'Key is required',
    keyDuplicate: 'Duplicate Key',
    actions_title: 'Actions',
    actions_subtitle: '',
    showAsCPActionButton: 'Show button',
    actionCode: 'Action code',
    modal: {
      attributes: {
        add: 'Add attribute',
        edit: 'Edit attribute',
      },
      attributeKeys: {
        add: 'Add attribute',
        edit: 'Edit attribute',
      },
      settings: {
        add: 'Add setting',
        edit: 'Edit setting',
      },
      details: {
        add: 'Add Identifier',
        edit: 'Edit detail',
      },
      actions: {
        add: 'Add action',
        edit: 'Edit action',
      },
    },
    notifications: {
      read: 'Read',
      new: 'New messagge',
      seeAll: 'See all notifications',
      detail: 'Notification detail',
      now: 'now',
      today: 'today',
      seconds: ' seconds ago',
      second: ' second ago',
      minutes: ' min ago',
      minute: ' min ago',
      hours: ' hours ago',
      hous: ' hour ago',
      day: ' day ago',
      days: ' days ago',
    },
    removeAll: 'Remove all',
    date: 'Date',
    url: 'URL',

    variantType: 'Variant Type',
    variantCode: 'Variant Code:',
    lot: 'Lot',
    label: 'Label',
    width: 'Width',
    measure: 'Measure',
    externalUrl: 'External URL',
    brand: 'Brand',
    line: 'Line',
    category: 'Category',
    merchandiseClass: 'Merchandise Class',
    merchandiseSubclass: 'Merchandise Subclass',
    productionClass: 'Production Class',
    productionSubclass: 'Production Subclass',
    department: 'Department',
    subdepartment: 'Sub-department',
    class: 'Class',
    subclass: 'Subclass',
    family: 'Family',
    theme: 'Theme',
    age: 'Age',
    collectionYear: 'Collection Year',
    collectionCode: 'Collection Code',
    collectionEvent: 'Collection Event',
    marketingDescription: 'Marketing Description',
    cites: 'Cites',
    compliance: 'Compliance',
    customsCode: 'Customs code',
    metal: 'Metal',
    shape: 'Shape',
    careInstructions: 'Care Instructions',
    weigthUom: 'Weight Unit of measure',
    weigthValue: 'Weight Value',
    volumeUom: 'Volume Unit of measure',
    volumeValue: 'Volume Value',
    dimensionUom: 'Dimension Unit of measure',
    dimensionHeight: 'Dimension Height',
    dimensionWidth: 'Dimension Width',
    dimensionDepth: 'Dimension Depth',
    pictureId: 'Picture',
    sketchId: 'Sketch',
    barcode: 'Barcode BC',
    ean: 'Ean',
    attributeCodeExist: 'Existing code',
    attributeCodeExistText: 'Select the option from the list of available codes',
    attributeCodeAdded: 'You have already associated this code with the entity',
    addCode: 'Add code',
    attributeConfiguration: 'Configuration',
    camelCaseCode: 'Only camelCase codes are valid e.g. attributeCode',
    allCaps: 'Use uppercase letters with underscores (e.g. CARE_LABEL)',
    export: 'Export',
    export_pdf: 'Export PDF',
    check_all: 'Check all',
    noSearchMatch: 'No results match your search',
    version: 'Version',
    buildDate: 'Build date',
    copyright: 'Copyright',
    rightReserved: 'All right reserved',
    regexFilter: 'Regex filter',
    optional: 'Optional',
    noRoles: 'You have no associated role',
    it: 'Italian',
    en: 'English',
  },
  paths: {
    places: 'Places',
  },
  report: {
    asn: 'Shipments',
    checklist: 'Checklists',
    pallet: 'Pallets',
    parcel: 'Parcels',
    parcelEntries: 'Parcel Entries',
    emptyParcelEntries: 'No parcel entries found',
    parcelViewHistory: 'View history',
    parcelEmptyHistory: 'No history found',
    item: 'Items',
    product: 'Products',
    itemIdentifier: 'Item Identifiers',
    sortingGroupRead: 'Sorting Group Read',
    pickingEntry: 'Picking Entries',
    productionorders: 'Production order',
    productionorderrows: 'Production order row',
    simpleItemSession: 'Simple Item Session',
    simpleItemSessionEntry: 'Simple Item Session Entry',
  },
  menu: {
    reports: 'Reports',
    configuration: 'Configuration',
    zones: 'Zone',
    places: 'Places',
    settings: 'Settings',
    enumerations: {
      label: 'Enumerations',
      child: {
        values: 'Values',
        types: 'Types',
      },
    },
    usersRoles: 'Users & Roles',
    roles: 'Roles',
    roleMappings: 'Role Mappings',
    users: 'Users',
    capabilitiesGroup: 'Capabilities group',
    operations: 'Operations',
    itemConfigurations: 'Item configurations',
    itemTypes: 'Item types',
    enumerationTypes: 'Enumeration types',
    enumerationValues: 'Enumeration values',
    devices: 'Devices',
    workstation: 'Workstation',
    rfidReaders: 'RFID Readers',
    antennas: 'Antennas',
    barcodeReaders: 'Barcode Readers',
    deviceControllers: 'Controllers',
    xspans: 'Xspans',
    scanners: 'Scanners',
    printers: 'Printers',
    conveyors: 'Conveyors',
    balances: 'Balances',
    scales: 'Scales',
    workstations: 'Workstations',
    entityAttributes: 'Entity attributes',
    notifications: 'Notifications',
    jobs: {
      jobs: 'Jobs',
      user: 'User',
      system: 'System',
      import: 'Import',
      export: 'Export',
    },
    pickingFlows: 'Picking Flows',
    applications: 'Applications',
    templates: 'Templates',
  },
  checklist: {
    place: 'CheckList',
    create_new: 'Create CheckList',
    edit: 'Edit CheckList',
    noProductsFoundTitle: 'No products found',
    noItemsFoundTitle: 'No items found',
    productRequired: 'You have to select a product',
  },
  simpleItemSessionList: {
    sessions: 'Sessions',
    noSessionsFound: 'No sessions found',
    sessionEntries: 'Entries',
    noSessionEntries: 'No entries found',
  },
  zone: {
    zone: 'Zona',
    create_new: 'Create Zone',
    edit: 'Edit zone',
  },
  place: {
    place: 'Place',
    create_new: 'Create Place',
    edit: 'Edit place',
    noZonesFoundTitle: 'No zones found',
  },
  operation: {
    operation: 'Operation',
    create_new: 'Create Operation',
    edit: 'Edit operation',
  },
  item: {
    noIdentifiersFoundTitle: 'No identifiers found',
    noEventsFoundTitle: 'No events found',
    attribute: {
      cqStatus: 'cqStatus',
      cqDisposal: 'cqDisposal',
      cqNote: 'cqNote',
    },
  },
  itemconfiguration: {
    create_new: 'Create Item configuration',
    edit: 'Edit item configuration',
    noDetailsFoundTitle: 'No details found',
  },
  itemtype: {
    create_new: 'Create Item type',
    edit: 'Edit item type',
    noDetailsFoundTitle: 'No type found',
  },
  application: {
    application: 'Application',
    create_new: 'Create Application',
    edit: 'Edit Application',
  },
  role: {
    role: 'Role',
    create_new: 'Create Role',
    edit: 'Edit role',
    noOperationsFoundTitle: 'No operations found',
  },
  roleMapping: {
    roleMapping: 'Role mapping',
    create_new: 'Create Role mapping',
    edit: 'Edit role mapping',
    noRolesFoundTitle: 'No roles found',
  },
  user: {
    user: 'User',
    create_new: 'Create User',
    edit: 'Edit user',
    noPlacesFoundTitle: 'No places found',
  },
  product: {
    user: 'Product',
    edit: 'Edit product',
  },
  deviceControllers: {
    deviceControllers: 'Device controller',
    create_new: 'Create Device controller',
    edit: 'Edit device controller',
  },
  xspans: {
    xspans: 'Xspan',
    create_new: 'Create Xspan',
    edit: 'Edit Xspan',
  },
  scanners: {
    scanners: 'Scanners',
    scanner: 'Scanner',
    create_new: 'Create Scanner',
    edit: 'Edit scanner',
    noAntennasFoundTitle: 'No RFID antennas found',
  },
  antennas: {
    antennas: 'Antennas',
    antenna: 'Antenna',
    create_new: 'Create Antenna',
    edit: 'Edit antenna',
  },
  printers: {
    printers: 'Printers',
    create_new: 'Create Printer',
    edit: 'Edit printer',
  },
  barcodeReaders: {
    barcodeReaders: 'Barcode readers',
    barcodeReader: 'Barcode reader',
    create_new: 'Create Barcode reader',
    edit: 'Edit barcode reader',
  },
  balances: {
    balances: 'Balances',
    create_new: 'Create Balance',
    edit: 'Edit balance',
  },
  scales: {
    balances: 'Scales',
    create_new: 'Create Scale',
    edit: 'Edit scale',
  },
  conveyors: {
    title: 'Conveyors',
    create_new: 'Create conveyor',
    edit: 'Edit conveyor',
  },
  rfidReaders: {
    rfidReaders: 'RFID readers',
    rfidReader: 'RFID reader',
    create_new: 'Create RFID Reader',
    edit: 'Edit RFID reader',
    noAntennasFoundTitle: 'No RFID antennas found',
    noPortsFoundTitle: 'No ports found',
  },
  workstations: {
    create_new: 'Create Workstation',
    edit: 'Edit workstation',
    noAntennasFoundTitle: 'No RFID antennas found',
  },
  enumeration: {
    types: {
      list: 'Enumeration Types',
      create_new: 'Create Type',
      edit: 'Edit type',
      added: 'Enumeration Type added correctly',
    },
    values: {
      list: 'Enumeration Values',
      create_new: 'Create Value',
      edit: 'Edit value',
      noFoundTitle: 'No values found',
    },
  },
  pickingFlow: {
    pickingFlows: 'Picking flows',
    create_new: 'Create Picking flow',
    edit: 'Edit Picking flow',
  },
  templates: {
    pickingFlows: 'Templates',
    create_new: 'Create template',
    edit: 'Edit templates',
  },
  products: {
    products: 'Products',
    create_new: 'Create product',
    edit: 'Edit product',
  },
  transferToZone: {
    noItemsFoundTitle: 'No items found',
  },
  simpleItemList: {
    noItemsFoundTitle: 'No items found',
  },
  outbound: {
    noProductsFoundTitle: 'No products found',
  },
  pallet: {
    noParcelsFoundTitle: 'No parcels found',
  },
  parcel: {
    noProductsFoundTitle: 'No products found',
    noParcelContentFoundTitle: 'No parcel content found',
  },
  inventory: {
    noItemsFoundTitle: 'No items found',
    noProductsFoundTitle: 'No products found',
  },
  inventoryParcel: {
    noParcelsFoundTitle: 'No parcels found',
  },
  asn: {
    parcels: 'Parcels',
    noParcelsFoundTitle: 'No parcels found',
  },
  errors: {
    error: 'error',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    authentication_failed: 'Authentication failed',
    unauthorized: 'Unauthorized',
    connection: 'Connection error',
    pdf: 'Error while generating the pdf',
  },
  messages: {
    export: {
      select_column_to_export: 'Select the columns to export',
    },
  },
  confirm: {},
  labels: {},
  capabilitiesGroup: {
    capabilitiesGroup: 'Capabilities group',
    create_new: 'Create capabilities group',
    edit: 'Edit capabilities group',
  },
  jobs: {
    jobs: 'Jobs',
    job_detail: 'Job detail',
    link: 'Click here to open jobs list.',
    status: {
      done: 'Done',
      in_progress: 'In progress',
      queued: 'Queued',
    },
    requestReceived: 'Request for job received.',
  },
  serverError: {
    missingCapabilities: 'Missing Capabilities',
  },
}
