import { Component, Fragment } from 'react'
import { CloseCircleTwoTone, CloseOutlined } from '@ant-design/icons'
import { isDayjs } from 'dayjs'
import { Button, Row, Typography } from 'antd'
import {
  AdvancedTableFilter,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
  AdvancedTableFilterValues,
} from './advancedTableTypes'
import AutocompleteApi from '../../api/AutocompleteApi'
import { BooleanComboValues } from '../../constants'
import { __ } from '../../shared/i18n'

const { Text } = Typography

interface Props {
  filterDefinitions: AdvancedTableFiltersDef
  filterValues: AdvancedTableFilterValues
  filterChangedHandler?: (filterValues: AdvancedTableFilterValues) => void
  enabledResetAll?: boolean
  customClass?: string
  filterApplied?: number
}

interface State {
  filtersData: FilterData[]
}

export class AdvancedTableFilterValuesButtons extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      filtersData: [],
    }
  }

  componentDidMount() {
    this.reloadFilterData(this.props.filterValues)
  }

  shouldComponentUpdate = (nextProps) => {
    const { filterValues, filterApplied } = this.props
    if (nextProps.filterValues && JSON.stringify(filterValues) !== JSON.stringify(nextProps.filterValues)) {
      this.reloadFilterData(nextProps.filterValues)
    } else if (nextProps.filterValues && filterApplied !== nextProps.filterApplied) {
      this.reloadFilterData(nextProps.filterValues)
    }
    return true
  }

  async reloadFilterData(filterValues) {
    const filterData = await this.buildFilterDefinition(filterValues)
    this.setState({
      filtersData: filterData,
    })
  }

  applyFilter(filters) {
    const { filterChangedHandler } = this.props
    filterChangedHandler?.(filters)
  }

  removeFilter(filterData: FilterData) {
    const newvalues = {
      ...this.props.filterValues,
    }
    newvalues[filterData.key] = undefined
    this.applyFilter(newvalues)
  }

  removeFilterValue(value: any, filterData: FilterData) {
    const newvalues = {
      ...this.props.filterValues,
    }
    const values: any[] = newvalues[filterData.key]
    values.splice(values.indexOf(value), 1)
    newvalues[filterData.key] = values
    this.applyFilter(newvalues)
  }

  getFilterDefinitionByKey(key: string): AdvancedTableFilter {
    return this.props.filterDefinitions.filter((def) => def.key === key)[0]
  }

  async buildFilterDefinition(filterValues): Promise<FilterData[]> {
    const filters: FilterData[] = []
    const promises: Promise<any>[] = []
    for (const key of Object.keys(filterValues)) {
      if (filterValues[key]) {
        const data: FilterData = {
          key: key,
          value: filterValues[key],
          def: this.getFilterDefinitionByKey(key),
        }

        let ids: string[] = []

        if (data.value !== undefined) {
          if (data.value instanceof Array) {
            ids = data.value
          } else {
            ids = [data.value]
          }
        }

        if (data.def && ids && ids.length > 0) {
          filters.push(data)
          if (data.def.type === AdvancedTableFilterKind.AUTOCOMPLETE) {
            promises.push(
              this.onSearch(ids, data.def).then((entities) => {
                data.screenValue = {}
                for (const v of ids) {
                  const entity = entities.filter((e) => e.id === v)[0]
                  data.screenValue[v] = entity.username || entity.description || entity.code || entity.id
                }
              })
            )
          }

          if (data.def.type === AdvancedTableFilterKind.BOOLEAN) {
            data.screenValue = BooleanComboValues.filter((value) => value.id === ids[0])[0].description
          }
        }
      }
    }

    await Promise.all(promises).then(() => {
      this.refreshFilterData()
    })
    return filters
  }

  // perchè viene settato lo state a se stesso?
  async refreshFilterData() {
    this.setState({
      filtersData: this.state.filtersData,
    })
  }

  async onSearch(ids: string[], def: AdvancedTableFilter): Promise<any[]> {
    if (ids.length === 0) {
      return []
    }

    const res = await AutocompleteApi.search<any>(def.autocomplete!.endpoint, {
      page: 0,
      size: ids.length,
      sort: def.autocomplete!.sort,
      ids: ids,
    })
    return res.content
  }

  formatDate = (date) => {
    return isDayjs(date) ? date.format('DD/MM/YYYY HH:mm:ss') : date
  }

  renderFilterValue(filterData: FilterData) {
    switch (filterData.def.type) {
      case AdvancedTableFilterKind.DATE_RANGE: {
        let dateRangeValue = ''
        if (filterData.value[0]) {
          dateRangeValue += this.formatDate(filterData.value[0])
        }
        if (filterData.value[1]) {
          dateRangeValue += (dateRangeValue !== '' ? ' -> ' : '') + this.formatDate(filterData.value[1])
        }
        return dateRangeValue !== '' ? this.renderButton(dateRangeValue, filterData) : null
      }

      case AdvancedTableFilterKind.BOOLEAN: {
        return this.renderButton(filterData.screenValue, filterData)
      }

      case AdvancedTableFilterKind.AUTOCOMPLETE: {
        const filterValue = Array.isArray(filterData.value) ? filterData.value : [filterData.value]
        return (
          <Fragment key={filterData.key}>
            {filterValue.map((id) => this.renderButton(filterData.screenValue[id]!, filterData, id))}
          </Fragment>
        )
      }

      case AdvancedTableFilterKind.STRING_LIST:
        return (
          <Fragment key={filterData.key}>{filterData.value.map((v) => this.renderButton(v, filterData, v))}</Fragment>
        )

      default:
        return this.renderButton(filterData.value, filterData)
    }
  }

  renderButtonValue(value: any) {
    if (Array.isArray(value)) {
      return value.join(',')
    }
    return value
  }

  renderButton(label: string, filterData: FilterData, currentValue?: any) {
    return (
      <Button
        type="primary"
        onClick={() => {
          if (!currentValue) {
            this.removeFilter(filterData)
          } else {
            this.removeFilterValue(currentValue, filterData)
          }
        }}
        style={{
          display: 'flex',
          minWidth: 'auto',
          alignItems: 'center',
          float: 'left',
          marginLeft: '10px',
          marginTop: '10px',
          paddingTop: '0',
          paddingBottom: '0',
          height: '24px',
          borderRadius: '10px',
        }}
        className="filter-tag"
        key={`${filterData.key}.${label}`}
      >
        {filterData.def && filterData.def.label && (
          <Text strong style={{ marginRight: 5 }}>{`${filterData.def.label}: `}</Text>
        )}
        <Text>{this.renderButtonValue(label || filterData.value)}</Text>
        <CloseOutlined key={`${filterData.key}.${label}.icon`} />
      </Button>
    )
  }

  render() {
    const { filtersData } = this.state
    const { filterChangedHandler, enabledResetAll, customClass } = this.props
    return filtersData && filtersData.length ? (
      <Row className={`stw-filters-selected ${customClass || ''}`}>
        <div style={{ flex: 1 }}>{filtersData && filtersData.map((filter) => this.renderFilterValue(filter))}</div>
        {enabledResetAll && (
          <div className="stw-filters-selected-reset">
            <Button
              type="text"
              onClick={() => {
                filterChangedHandler?.({})
              }}
            >
              <CloseCircleTwoTone twoToneColor={['white', 'grey']} style={{ fontSize: 24, height: 24 }} />
              {__('misc.resetAll')}
            </Button>
          </div>
        )}
      </Row>
    ) : null
  }
}

interface FilterData {
  key: string
  value: any
  def: AdvancedTableFilter
  screenValue?: any
}
